.dateRangePickerInput {
    border: none;
    .MuiOutlinedInput-root {
        background-color: #1d1e2c;
        border: 1px solid #2b2d42;
        border-radius: 10px;
        color: #b1c2df;
        font-family: Montserrat;
        font-size: 14px;
        svg {
            color: #dbdfea;
        }
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid #2b2d42 !important;
        }
    }
    // .MuiOutlinedInput-root:focus {
    //     background-color: #1D1E2C;
    //     border: 1px solid #2B2D42;
    //     border-radius: 10px;
    // }
}

.dateRangeModal {
    z-index: 9999 !important;
    .dateRangePickerComp {
        .MuiPaper-root {
            background-color: #1d1e2c;
            color: rgb(218, 220, 238);
            svg,
            p,
            span {
                color: rgb(218, 220, 238);
            }
            [class*='materialui-daterange-picker-MuiSelect-select'] {
                color: rgb(218, 220, 238);
            }
            [class*='materialui-daterange-picker-makeStyles-outlined'] {
                border: 1px solid #5d97f6;
            }
            [class*='materialui-daterange-picker-makeStyles-filled'] {
                background-color: #5d97f6;
            }
        }
    }
}
