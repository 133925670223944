.broadCast {
    .scheduleBtn {
        margin-right: 28px;
        margin-bottom: 10px;
        width: 180px;
        height: 44px;
        font-weight: 600;
        padding: 0px;
        text-align: center;
    }
}
