.spocDetails {
    display: flex;
    flex-direction: column;
    margin: 20px;
    .btnDiv {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        .addContact {
            width: 145px;
            height: 44px;
            font-weight: 600;
            font-size: 14px !important;
        }
    }
    .marginBtm {
        margin-bottom: 20px;
    }
    .spocCard {
        border: 1px solid #2b2d42;
        border-radius: 6px;
        background-color: #1d1e2c !important;
        padding: 10px;
        box-shadow: none;
    }
}
