.machineOverviewCard {
    border: 1px solid #2b2d42;
    border-radius: 6px;
    background-color: #1d1e2c !important;
    padding: 10px;
    box-shadow: none;
    min-height: 110px;

    .machineOverviewCardRoot {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        padding-bottom: 7px;

        .imageDiv {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 20px;
            margin-top: -10%;
        }

        .contentDiv {
            flex: 70%;
            h5 {
                font-size: 11px;
                margin-bottom: 2px;
                color: #b1c2df;
            }

            p {
                font-size: 16px;
                color: rgb(177 194 223 / 80%);
                margin-bottom: 0px;
            }
            .paraGraph {
                color: rgb(177 194 223 / 80%);
                font-size: 12px;

                .location {
                    margin-top: -8px;
                    margin-right: 5px;
                }

                .time {
                    margin-top: -2px;
                    margin-right: 5px;
                }
            }
        }
        .noType {
            p{
                margin-bottom: 1rem;
            }
            h5 {
                margin-bottom: 0.5rem;
                min-height: 40px;
            }
        }
    }
}
.clickable {
    cursor: pointer;
}
.noPadding {
    padding: 0px;
}
.hfivetitle{
    min-height: 28px;
}
.hfivetitle.no_Type{
    min-height: auto;
}
.timediv{
    margin-top: 10px;
    margin-bottom: 10px !important;
}