.machineMonitoringCard {
    background-color: #1d1e2c !important;
    border: 1px solid #2b2d42 !important;
    box-shadow: none !important;
    border-radius: 6px !important;
    min-height: 100%;
    margin-bottom: 20px;
    .machineBlock {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        padding-bottom: 0px;
    }
    .cardMaxWidth {
        max-width: 63%;
    }
    .title {
        color: #dadcee;
        font-size: 14px;
    }
    .line {
        color: #9cc2ff;
        font-size: 12px;
    }
    .paraGraph {
        color: rgb(177 194 223 / 80%);
        font-size: 12px;
        .location {
            margin-top: -8px;
            margin-right: 5px;
        }
        .time {
            margin-top: -2px;
            margin-right: 5px;
        }
    }
}
