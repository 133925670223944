.drawerDiv {
    .MuiPopover-root {
        z-index: 99999 !important;
    }
    .MuiPaper-root {
        background-color: #24263a;
    }
    .standardBox {
        width: 40vw;
    }
    .fullWidth {
        width: 95vw;
    }
    .modelWidth {
        width: 99vw;
    }
}
.drawerDivAlert {
    .MuiPopover-root {
        z-index: 99 !important;
    }
    .MuiPaper-root {
        background-color: #24263a;
    }
    .standardBox {
        width: 96vw;
    }
    .fullWidth {
        width: 100vw;
    }
    .modelWidth {
        width: 99vw;
    }
}
@media (max-width: 767px) {
    .drawerDiv {
        .standardBox {
            width: 100vw;
        }
    }
    .drawerDivAlert {
        .standardBox {
            width: 100vw;
        }
    }
}
