.acgDropdown {
    .Mui-disabled {
        span {
            -webkit-text-fill-color: white !important;
        }
    }
}
.setHeight {
    height: auto;
    max-height: 300px !important;
    width: 350px;
    overflow: auto !important;
    background-color: #1d1e2c !important;
}
