.clipboard-copy {
    font-family: 'Montserrat';
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0px;
    .MuiInputBase-root {
        color: white;
        border: 1px solid #5d97f6;
        border-radius: 10px;
        background: transparent;
        color: white !important;
        font-family: 'Montserrat';
    }
    .MuiInputBase-root .Mui-disabled {
        -webkit-text-fill-color: white !important;
    }
}
