.dashboard.OEEInsights {
    padding: 0 12px;
    margin: 0px;
    // height: 100vh
}
.innerCardGroup {
    padding: 10px 0 0 0;
    height: calc(100vh - 9rem);
}

.rightFilters {
    // float: left;
    // width: 240px;
    height: 100%;
}

.leftBox {
    height: calc(100vh - 142px);
    // float: right;
    // width: calc(100% - 250px);
}

.rightFilters .width_filter {
    width: 100%;
    margin: 0 0 15px 0;
}

.rightFilters .filterdiv {
    display: block;
    padding: 23px 15px 10px 15px;
    height: 100%;
    margin-left: 10px;
}
.rightFilters .filterdiv .padingLR .MuiSelect-select span,
.rightFilters .custom-date-picker .MuiInputBase-input{
font-size: 11px;
}

.arrowBack svg{
    // position: absolute;
    background: #5d97f6;
    border-radius: 50%;
    margin-left: 5px;
}
.refreshICOns svg{
    width: 0.6em !important;
    height: 0.6em !important;
}
.cardbox {
    display: flex;
    background: #2b2d42;
    padding: 10px 10px;
    border-radius: 8px 8px 0px 0px;
    align-items: center;
    .cardInner {
        background: transparent;

        .activeCardBg {
            background-color: #1565c0;
        }

        .MuiCardContent-root {
            padding: 10px;
        }

        p {
            margin-bottom: 0;
            font-family: "Montserrat-Medium";
        }
    }
}
.content-expanded .cardbox {
    // padding-left: 50px;
    padding-right: 50px;
}
.chatsection {
    display: flex;
    overflow-y: auto;
    background: #2b2d42;
    border-radius: 0 0 8px 8px;
    .innerChat {
        height: calc(100vh - 212px);
        width: 100%;
    }
    p{
        font-size: 12px;
    }
    h1, h2, h3, h4, h5, h6{
        font-size: 14px;
    }
    img {
        // width: -webkit-fill-available;
        // height: 100%;
    }
}
.rightFilters .lableTitle {
    color: #c0c2d8;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    // margin: 0;
}
.rightFilters .btns button{
    color: #000000;
    font-size: 12px;
    background: #5d97f6;
    text-align: right;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0;
    height: 34px;
    min-width: 100px;
    margin-top: 10px;
}
.rightFilters{
    position: relative;
}
.chatsection .MuiBox-root .userbottom button svg{
    height: 15px;
    width: 15px;
}
.chatsection .MuiBox-root button svg:hover{
    fill: #5d97f6;
}
.chatsection .MuiAvatar-root, .chatsection .MuiAvatar-root .MuiAvatar-img{
    height: 30px !important;
    width: 30px !important;
    background: #edf4fd;
    padding: 3px;
    border: 0.5px solid grey;
    color: #000;
}
.chatsection .MuiAvatar-colorDefault{
    margin-left: 15px;
}
.chatsection .bgBtns{
    background: #4b4a62;
}
.chatsection .ActivebgBtns{
    background: #5d97f6;
}
.chatsection .bgBtns:hover{
    background: #1e1d2c;
}
.chatsection .MuiInputBase-root input{
    height: 40px;
}
.userbottom{
    display: flex;
    justify-content: center;
    align-items: end;
}
.imputboxx input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: #4e5664 !important;
}
.userbottom ol li,
.userbottom ul li,
.userbottom ol ul li,
.userbottom ul ol li {
    line-height: 24px;
}
.userbottom ol li p, .userbottom ul li p {
    margin-bottom: 0;
}
.userbottom table{
    margin-bottom: 15px;
    margin-top: 10px;
}
.userbottom td, .userbottom th{
    padding: 5px;
    border: 1px solid #b1c2df;
}
.userbottom p p {
    margin-bottom: 10px;
}

.userbottom p {
    margin-bottom: 0px;
}
.chatsection .MuiBox-root .MuiBox-root span{
    // left: 50px;
    // bottom: 10px;
}
.chatsection .MuiBox-root .Mui-focused{
    border: 1px solid #b1c2df;
}
.chatsection fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #b1c2df;
    border-width: 1px !important;
    height: 40px;
    top: 0px;
}
.chatsection fieldset legend{
    display: none;
}
.chatsection pre code, .chatsection code{
color: #000;
font-size: 12px;
font-family: "montserrat";
}
.accordianChat{
    color: #e0e0e0;
    margin-bottom: 15px;
    display: none;
}
.accordianChat .MuiPaper-root{
// background-color: #fff !important;
// color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    color: #e0e0e0;
}
.mbzeros, .userbottom .accordianChat p{
    margin-bottom: 0;
}
.accordianChat svg{
    fill: #e0e0e0;
}
.accordianChat .MuiAccordion-root{
    border: 1px solid rgb(255 255 255 / 12%);
}
.accordianChat .MuiAccordionDetails-root {
    border-top: 1px solid rgb(255 255 255 / 12%);
}
.accordianChat .MuiAccordionSummary-root{
    height: 40px;
    min-height: 40px;
    background-color: #1d1e2c;
}
.userbottom img{
    height: auto !important;
}
.userbottom .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    box-shadow: none !important;
}
.headinglevel{
    padding: 10px 40px;
    color: #24263A;
    }
    .datacard{
        margin: 0px 30px 30px 40px;
        color: #24263A;
    }
    .downloadDT{
        color: #24263A;
    }
    .selectedFilters{
        margin-bottom: 15px;
        font-style: 12px;
        color: #24263A;
        width: 100%;
        display: flex;
    }
    #divids{
        font-size: 14px;
    }
    .pdflogo{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .MuiListItemButton-root{

    }
    .MuiTooltip-popper{
        z-index: 10000 !important;
    }