.machineMonitoringTopCard {
  border-radius: 6px !important;
  box-shadow: none !important;

  .machinePaper {
    background-color: hsl(259deg 100% 76% / 8%) !important;
  }

  .alarmPaper {
    background-color: #2f2d3e !important;
  }

  .major {
    color: #f6c66a;
  }

  .minor {
    color: #98a2ff;
  }

  .totalMachines {
    color: #ad86ff;
  }

  .critical {
    color: #fd8440;
  }

  .failure {
    color: #d2666d;
  }

  .online {
    color: #37bb52;
  }

  .offline {
    color: #8d8f91;
  }

  .totalAlarms {
    color: #ffaa86;
  }

  .paper {
    padding: 22px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 85px;
    background-color: transparent;
    box-shadow: none !important;
    border-radius: 6px 0px 0px 6px;

    .infoDiv {
      margin-left: 20px;
      color: #dbdfea;
      font-size: 12px;
      font-family: montserrat;

      p {
        margin-bottom: 0px;
        font-size: 16px;
      }
    }
  }

  .machineDetailButton {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    top: 27%;
  }

  .borderRight {
    width: 16%;
    height: 60px;
    border-right: 1px solid rgb(137 139 167 / 20%);
    color: #fff;
    position: absolute;
    top: 14%;
    // opacity: 0.2;
  }

  .btnMachine {
    top: 23%;
    display: flex;
    margin: "0 auto";
  }
}

@media (max-width: 767px) {
  .machineMonitoringTopCard {
    .borderRight {
      display: none;
    }

    .paper {
      border-bottom: 1px solid rgb(137 139 167 / 20%);
    }

    .machineDetailButton {
      margin-bottom: 40px;
    }
  }
}

@media(min-width:767px) and (max-width:1023px) {
  .machineMonitoringTopCard {
    .responsiveFirstGridItem {
      flex: 20%;
      max-width: 17vw
    }

    .borderRight {
      width: 20%;
    }
  }
}