.cardContainer {
    padding-left: 3px;
    padding-right: 3px;
    height: 300px;
}

.cardStyle {
    border-radius: 0px;
    background-color: #422b2b;
    overflow: inherit;
    padding: 10px; /* Optional padding inside the card */
    height: 500px;
    display: flex;
    gap: 10px;
}

.divStyle {
    height: 350px;
    width: 50%;
    background-color: #3b3f58; /* Background color for the first div */
    margin-bottom: 10px; /* Gap between divs */
}

.divStyleSecond {
    height: 350px;
    width: 50%;
    background-color: #4e5c76; /* Background color for the second div */
}

.responsive-charts-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive layout */
    gap: 20px;
    padding: 20px;
  }
  
  .chart-container {
    background-color: #1d1d1d; /* Dark background for each chart */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    color: white;
  }
  
  .chart-container h3 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .availability {
    background-color: #0055ff; /* Special background color for Availability */
  }
  
  .quality {
    background-color: #00ff00; /* Special background color for Quality */
  }
  
  .performance {
    background-color: #ffcc00; /* Special background color for Performance */
  }
