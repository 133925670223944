.dashboard.PMEC-Dash.goldbatch{
    padding-bottom: 5px;
}
.mainGridItem{
    position: relative;
    .arrowSwapActive {
        top: 64px;
    }
}
.active-alerts {
    background: #2b2d42;
    margin-top: 10px;
    font-family: "Montserrat";
    color: #DADCEE;
    padding: 0px 10px 10px 10px;
    font-size: 10px;
}
.active-alerts h3{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    color: #DADCEE;
    margin-bottom: 5px;
    padding-top: 5px;
}
.innerCards {
    min-height: 128px;
    background-color: #1d1e2c;
    border: 3px solid #2b2d42;
    box-shadow: none;
    border-radius: 6px;
    padding: 5px 12px;
    margin-right: 10px;
    max-width: 374px;
    min-width: 374px;
}
.borderanimation{
    //animation: border-pulsate 2s infinite;
    animation: border-pulsate 2s ease;
    animation-iteration-count: 10;
}
@keyframes border-pulsate {
    0%   { 
        border-color: rgba(255, 191, 0, 1); 
        transform: scale(1);
    }
    50% { 
        border-color: rgba(255, 191, 0, 0);
        transform: scale(0.99);
    }
    100%   { 
        border-color: rgba(255, 191, 0, 1);
        transform: scale(1);
     }
}
.idTitle, .innereTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    margin-bottom: 2px;
}
.innereTitle {
    margin-bottom: 10px;
}
.btnAct {
    display: flex;
    width: 100%;
    margin-top: 5px;
    justify-content: space-between;
    padding: 5px 0;
}
.btnAct button{
    width: 100%;
    // background: #999696;
    background-color: #5d97f6;
    border-radius: 4px;
    height: 24px;
    color: #fff;
    display: block;
    box-shadow: none;
    border: none;
    font-weight: normal;
    min-width: 100px;
}
.btnAct button.MuiButton-root.Mui-disabled{
background: #2b2d42;
}
.btnAct button:nth-child(2){
    margin: 10px 0;
}
.ackNoledge {
    background: #5d97f6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    height: 24px;
}
.closeIcons{
    cursor: pointer;
    margin-right: -8px;
}
.idnumber {
    width: 28px;
    font-weight: bold;
}
.viewIcons {
    width: 16px;
    text-align: center;
    background: #000;
    height: 16px;
    display: flex;
    text-align: center;
    justify-content: flex-end;
    padding: 0px;
    border-radius: 50%;
    cursor: pointer;
}
.serialnumber {
    width: calc(100% - 56px);
}
.closeIcons svg{
    transform: scale(0.8);
    opacity: 0.5;
    cursor: no-drop;
}
.viewIcons svg:hover path{
    stroke: #5d97f6;
} 
.closeIcons svg:hover{
    opacity: 1;
} 
.btnAct button.MuiButtonBase-root:hover, .ackNoledge:hover{
    background: #1565c0;
}
.dropboxbtn .acgDropdown{
    border: 1px solid #999;
    border-radius: 6px;
    min-width: 135px;
}
.widthbtnn {
    // width: 70px !important;
    font-size: 11px !important;
    text-align: center !important;
    padding: 0 2px !important;
}
.submitBtn {
}
.dropboxbtn{
}
.dropboxbtn .MuiInputBase-root{
height: 28px !important;
}
.dropboxbtn .MuiSelect-select span{
    font-size: 11px;
}
.dropboxbtn .acgDropdown .MuiSelect-select{
    min-height: 24px;
    padding-top: 0;
    padding-bottom: 0;
}
.cardwrap {
    overflow-x: auto;
}
.scrollCards{
    // width: 1800px;
    max-width: max-content;
    display: flex;
}
.activeGB .filterdata p.ptag {
    background: #1d1e2c;
    padding: 5px 10px;
    margin-right: 5px;
    color: #e0e0e0;
    font-size: 11px;
    border: 0px solid rgba(137, 139, 167, 0.3);
    min-height: 40px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.activeGB .filterdata p .itag{
    padding-left: 5px;
}