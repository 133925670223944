.phoneFlag {
    font-family: Montserrat !important;
    .PhoneInputCountrySelect {
        height: 41px !important;
        background-color: #1d1e2c;
        color: #b1c2df;
    }
    input:focus-visible {
        outline: none !important;
    }

    .PhoneInputInput {
        border-color: #2b2d42 !important;
        border-top: none;
        border-left: none;
        border-width: 1px !important;
        border-radius: 10px;
        height: 41px !important;
        background-color: #1d1e2c;
        color: #b1c2df;
        font-size: 14px;
        font-weight: 500;
        font-family: Montserrat !important;
        letter-spacing: 0;
    }
    .PhoneInputCountrySelectArrow {
        color: #b1c2df;
    }
}
.phoneRedOutline {
    .PhoneInputInput {
        border: 1px solid #f06868 !important;
    }
}
