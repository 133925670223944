@import '../../../utilities/theme/_theme.scss';
@import '../../../assets/fonts/typography.scss';

.complianceWrapper {
    width: auto;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px !important;
    // padding-bottom: 10px !important;
    background-color: #2b2d42;
    border-bottom: 1px solid rgba(137, 139, 167, 0.3);
    position: relative;
    left: 3px;
    width: 99.55%;
    overflow: inherit;
    padding: 8px 22px;
    .section-buttons {
        font-size: 14px;
        color: #b1c2df !important;
        background-color: #2b2d42;
        .MuiRadio-root {
            color: #b1c2df;
        }
        .Mui-checked {
            color: #5d97f6;
        }
        .user-client-dropdown {
            //  max-height: 36px;
            height: 40px;
            max-width: 350px;
            font-size: 10px;
            color: #b1c2df;
            .MuiSelect-iconOutlined {
                margin-top: -3px;
            }
        }
    }
    label {
        font-size: 12px;
        color: #c0c2d8;
    }
    .fieldBox1 {
        display: flex;

        width: 100%;
        .fieldHealthFormHeader {
            display: flex;
            flex: 77%;
        }
        .educationIcon {
            margin: 13px;
            height: 45px;
            width: 4%;
            margin-left: 20px;
        }
        .educationType {
            // color: $s900Bg;
            color: #c0c2d8;
            // @extend .c-poppins-regular;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 27px;
            margin: 22px 22px;
        }
        .addButton {
            text-transform: initial;
            padding: 8px;
            @extend .c-poppins-medium;
            box-shadow: 0 3px 24px 0 rgba(15, 28, 143, 0.4) !important;
            span {
                margin-right: 10px !important;
                margin-top: 1px;
                font-size: 13px;
            }
            .addIcon {
                height: 24px;
                width: 24px;
            }
            .DownloadIcon {
                height: 16px;
                width: 24px;
                margin-right: 4px;
            }
        }
        .noShadow {
            box-shadow: none !important;
            transition: all 0.3s linear;
        }
        .button-block {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }
    }
    .border {
        width: 100%;
        height: 30px;
        opacity: 0.8;
        background-color: #ffffff;
        border: none !important;
        border-top: 1px solid #dee2e6 !important;
        border-bottom: 1px solid #dee2e6 !important;
    }
    .heading {
        height: 20px;
        width: 40%;
        color: #25243b;
        @extend .c-poppins-regular;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        margin: 5px 19px;
    }
    .fieldBox2 {
        width: 100%;
        display: flex;
        margin-top: 10px;
        .columns_alignment {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            top: 3px;
            padding-left: 19px;
            .gradeWrap {
                display: flex;
                flex: 14.2%;
                .numberBox {
                    height: 20px;
                    width: 20px;
                    padding: 2px;
                    border-radius: 11px;
                    background-color: #8b929c80;
                    margin: 7px 6px;
                }
                .numberLine {
                    color: #25243b;
                    @extend .c-poppins-regular;
                    font-size: 9px;
                    font-weight: 700;
                    letter-spacing: 0;
                    line-height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .grade {
                    color: #25243b;
                    @extend .c-poppins-regular;
                    font-size: 10px;
                    margin: 9px 5px;
                    width: 70%;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .complianceWrapper {
        //  max-width: fit-content;
        height: fit-content;
        overflow: scroll;
        .fieldBox1 {
            flex-direction: column;
        }
    }

    .complianceWrapper .fieldBox1 .educationIcon {
        width: 12%;
    }
    .complianceWrapper .fieldBox2 .columns_alignment {
        column-gap: 80px;
    }
}

@media (min-width: 767px) and (max-width: 900px) {
    .complianceWrapper .fieldBox1 .fieldHealthFormHeader {
        flex: 48%;
    }
}

@media (min-width: 800px) and (max-width: 999px) {
    .complianceWrapper {
        .fieldBox2 .columns_alignment .gradeWrap {
            flex: 20.2%;
        }
    }
}
