.modalTableCard {
    box-shadow: none !important;
    // margin-bottom: 20px !important;
    // width: 300px;
    max-width: 300px;
    // height: 90px;
    background-color: #1d1e2c !important;
    border: 1px solid #2b2d42 !important;
    border-radius: 6px !important;
    .mainDiv {
        padding: 15px;
        background-color: #1d1e2c;

        .tableImage {
            background-color: rgba(93, 151, 246, 0.2);
            width: 60px;
            height: 60px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .headingCard {
            color: #dadcee;
            font-size: 13px;
            margin-bottom: 10px;
            // margin-left: 12px;
        }

        .paraDiv {
            padding-left: 15px;
            position: relative;

            .mainPara {
                font-size: 10px;
                color: #9cc2ff;
                margin-bottom: 0;
                font-weight: 600;
            }

            .subPara {
                font-size: 12px;
                color: #b1c2df;
                margin-bottom: 0;
            }

            .borderRight {
                width: 40%;
                height: 25px;
                border-right: 1px solid #3d485a;
                color: #fff;
                position: absolute;
                top: 60%;
            }
        }
    }
}
