.timeSeriesDtl {
    overflow-x: scroll;
    padding: 5px 15px 0px 15px;
    .nameDiv {
        margin-top: 25px;
    }
    .dpDiv {
        margin-top: 7px;
        height: 46px;
    }
    .graphFormTS{
    .selfPad {
        .MuiInputBase-root {
            input {
                padding: 7px 14px !important;
            }
        }
    }
    }
    .graphTimePicker {
        width: 300px;
        margin-top: 55px;

        .dateRangePickerInput .MuiOutlinedInput-root {
            width: 300px;
            padding: 5px;
            border: none;
            left: 15px;
            fieldset {
                border: transparent;
            }
        }
    }
}
.ChartDiv {
    height: 73vh;
    width: calc(100% + 50%);
    overflow-x: scroll;
    h6 {
        position: absolute;
        color: rgba(0, 0, 0, 0.3);
        font-size: 2em;
        display: flex;
        left: 45%;
        top: 50%;
    }
    h6:before {
        content: attr(data-text);
        position: absolute;
        overflow: hidden;
        max-width: 7em;
        white-space: nowrap;
        color: #fff;
        animation: loading 5s infinite;
    }
    @keyframes loading {
        0% {
            max-width: 0;
        }
    }
}
.ChartDiv1 {
    height: 73vh;
    width: 100%
    h6 {
        position: absolute;
        color: rgba(0, 0, 0, 0.3);
        font-size: 2em;
        display: flex;
        left: 45%;
        top: 50%;
    }
    h6:before {
        content: attr(data-text);
        position: absolute;
        overflow: hidden;
        max-width: 7em;
        white-space: nowrap;
        color: #fff;
    }
    @keyframes loading {
        0% {
            max-width: 0;
        }
    }
}
.topRow .searchDiv.margintp .drawerContent .searchBox{
margin-top: 0px;
}
.parameterBtns button{
    text-transform: capitalize;
}
.errormsgposition{
    font-size: 11px;
    font-weight: 600;
    display: block;
    margin-top: 5px;
}
.comnformalign {
    width: 220px;
    float: left;
}