.dashboard {
    background-color: #1d1e2c;
    min-height: 100vh;
    margin-left: 60px;
    padding-top: 25px;
    padding-bottom: 20px;
}

.dn-rp-heading-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    // margin-top: 50px;
    margin-top: 0px;
    margin-left: 20px;
    color: #dadcee;
}
@media (max-width: 767px) {
    .dashboard {
        margin-left: 0px;
    }
}
