.btnBox {
    // border: 1px solid red;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

}

.btnBox button {
    font-weight: 600;
    height: 44px;
    margin-bottom: 10px;
    padding: 0;
    width: 145px;
    font-size: 14px;
    margin-left: 28px;
}

.errorss {

    color: #F06868;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    margin-top:5px;
    font-family: 'Montserrat';
    // margin-left: 5%;
}