.svg-font-color svg > path {
    fill: var(--ifm-font-color-base);
  }
  
  .queryBuilder {
    min-width: 420px;
  }
  .wrapper-cm {
    padding-left: 0px !important;
    overflow: auto !important;
  } 
  .all-labelcls fieldset {
    color: #ffff !important;
  }
  .css-uob957-MuiListSubheader-root{
    color: #000000 !important;
  }
  
  .queryBuilder > .ruleGroup .ruleGroup > .ruleGroup-header >.ruleGroup-addGroup{
    display: none;
  }
  
  .queryBuilder > .ruleGroup.ruleGroup > .ruleGroup-header > .ruleGroup-addRule {
      display: none;
  }
  
  .ruleGroup {
    background: rgb(36 38 58) !important;
  }
  
  .ruleGroup-addGroup {
    background-color: #5d97f6 !important;
  }
  
  .ruleGroup-addRule {
    background-color: #5d97f6 !important;
  }
  .ruleGroup-remove,
  .rule-remove {
  float: right;
  background: transparent;
  border: none;
  }
  
  .rule-remove, .ruleGroup-remove {
    background-color: #5d97f6 !important;
  }
  .ruleGroup-header button.ruleGroup-remove,
.rule-remove, .ruleGroup-remove {
    min-width: 4px;
    padding: 0 !important;
    line-height: 1.2;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}
  .validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid {
    background-color: rgba(102, 51, 153, 0.4);
  }
  .validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid .ruleGroup-addRule {
    font-weight: bold !important;
  }
  .validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid > .ruleGroup-header::after {
    content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
    color: white;
  }
  .validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value {
    background-color: rgba(102, 51, 153, 0.4);
  }
  .validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value::placeholder {
    color: #47246b;
  }
  
  html[data-theme="dark"]
    .validateQuery
    .queryBuilder
    .rule.queryBuilder-invalid
    .rule-value::placeholder {
    color: #945ec9;
  }
  
  .justifiedLayout .queryBuilder .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
  .justifiedLayout .queryBuilder .ruleGroup-addGroup + button.ruleGroup-lock,
  .justifiedLayout .queryBuilder .ruleGroup-addGroup + button.ruleGroup-remove,
  .justifiedLayout .queryBuilder .rule-operators + button.rule-cloneRule,
  .justifiedLayout .queryBuilder .rule-operators + button.rule-lock,
  .justifiedLayout .queryBuilder .rule-operators + button.rule-remove,
  .justifiedLayout .queryBuilder .rule-value + button.rule-cloneRule,
  .justifiedLayout .queryBuilder .rule-value + button.rule-lock,
  .justifiedLayout .queryBuilder .rule-value + button.rule-remove,
  .justifiedLayout .queryBuilder .control + button.rule-cloneRule,
  .justifiedLayout .queryBuilder .control + button.rule-lock,
  .justifiedLayout .queryBuilder .control + button.rule-remove,
  .justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-cloneRule,
  .justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-lock,
  .justifiedLayout .queryBuilder .chakra-select__wrapper + button.rule-remove {
    margin-left: auto;
  }
  .alldropbox span{
    color: #e0e0e0;
  }
  .alldropbox svg path{
    fill: #e0e0e0;
  }
  .all-labelcls .alldropbox .MuiInputBase-root {
    height: auto !important;
  }
  .all-labelcls .alldropbox.extracls .MuiInputBase-root {
    padding: 1px !important;
  }
 .fontSizeSpan .MuiFormControlLabel-label{
    font-size: 12px !important;
  }
  #checkboxes-tags-demo{
    padding-left: 15px !important;
  }
  .fmeacard .ruleGroup-body fieldset label{
    margin-left: 0;
  }
  .fmeacard .ruleGroup-body fieldset label span{
    font-size: 12px;
  }
  .fmeacard .ruleGroup-body fieldset label .MuiRadio-colorPrimary{
    padding: 5px;
  }
  .fmeacard .ruleGroup-body fieldset label .MuiRadio-colorPrimary svg{
    height: 0.6em;
    width: 0.6em;
  }
  .fmeacard .ruleGroup-body fieldset label .MuiRadio-colorPrimary svg path{
    fill: #e0e0e0;
  }
  .fmeacard .ruleGroup-body fieldset label .Mui-checked.MuiRadio-colorPrimary svg[data-testid="RadioButtonCheckedIcon"] path,
  .fmeacard .ruleGroup-body fieldset label .Mui-checked.MuiRadio-colorPrimary svg[data-testid="RadioButtonUncheckedIcon"] path{
fill: #5d97f6;
  }
  .alignmultibox #checkboxes-tags-demo .MuiInputBase-root input#checkboxes-tags-demo {
    min-width: 10px;
} 