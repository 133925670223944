.createReport {
    font-family: 'Montserrat';

    .labelDiv {
        margin-top: 25px;
    }

    .inputDiv {
        margin-top: 0px;
        height: 40px;
    }

    .longInputDiv {
        margin-top: 7px;
        height: 80px;
    }

    .btns {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        margin: 30px 0px 0 0;

        .first-btn {
            margin-right: 28px;
            margin-bottom: 10px;
            width: 145px;
            height: 44px;
            font-weight: 600;
            padding: 0px;
        }

        .second-btn {
            width: 145px;
            height: 44px;
            font-weight: 600;
            font-size: 14px !important;
        }
    }

    .userDetailDiv {
        background-color: #2d2f44;
        width: 90%;
        margin: 0 auto;
        height: 70px;
        margin-top: 20px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        padding: 10px;
        color: white;

        .detailDivChild {
            width: 49%;
            text-align: center;
            color: #98bcf8;
            padding: 8px;

            p {
                font-size: 10px;
                margin-bottom: 0rem;
                line-height: 15px;
            }

            h6 {
                font-size: 12px;
                margin-top: 7px;
            }
        }

        .firstChild {
            border-right: 1px solid #414d62;
        }
    }

    .binIcon {
        height: 34px;
        width: 34px;
        margin: 15px 0px 0px 10px;
        cursor: pointer;
    }

    .lineName {
        display: flex;
    }

    .plantLine {
        display: flex;
        margin-top: 17px;
        color: #5d97f6;
        //width: 100px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;

        span {
            margin-right: 10px !important;
            margin-top: 1px;
            font-size: 13px;
        }

        .PlusIcon {
            height: 24px;
            width: 24px;
            margin-right: 10px;
        }
    }
}

@media (max-width: 767px) {
    .createReport {
        .noWrap {
            flex-wrap: wrap !important;
            justify-content: space-around;
        }

        .first-btn {
            margin-right: 0px !important;
        }
    }
}
.createReport.filtrcrt{
    height: 100%;
}
.createReport .mbzero.labelDiv{
    margin-top: 10px;
}
.bordernone .inputDiv .MuiInputBase-root{
    border-radius: 0 !important;
}
.bordernone .inputDiv .MuiInputBase-root input{
    font-size: 12px;
}
.tableuploaddata .tableColumn.clcTh .MuiTableCell-root{
    text-align: left;
}
.createReport .cppClsMain .labelDiv.mbtop {
    margin-top: 25px;
}
.createReport .cppClsMain .labelDiv {
    margin-top: 0px;
}
.cppClsMain .MuiGrid-root.MuiGrid-item {
    padding-top: 10px !important;
}