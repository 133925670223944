.acgRadio {
    .MuiRadio-root {
        color: #8793AD !important;
        font-family: 'Montserrat';

        .Mui-checked {
            color: #5d97f6;
        }

    }

    .MuiFormControlLabel-label.Mui-disabled {
        color: #8793AD;
    }

    .MuiTypography-root {
        color: #b1c2df;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat';
    }

    // span.MuiButtonBase-root.MuiRadio-root {
    //     color:#8793AD !important;
    // }
    .MuiFormHelperText-root {
        color: red;
        font-size: 12px;
        line-height: 14px;
        margin-top: 10px;
    }
}