@import '../../../../assets/fonts/typography.scss';

.tableColumn .MuiTableCell-root {
  //   @extend .c-poppins-semibold;
  font-family: Montserrat;
  font-size: 13px !important;
  background-color: #2e304a;
    padding: 6px 24px;
    position: relative;
    line-height: 1;
    // left: 5px;
    color: #c0c2d8;
    border-bottom: none;
    span.Mui-active {
        color: #c0c2d8;
    }
    span:not(.Mui-active) {
      color: #c0c2d8;
  }
 .MuiButtonBase-root-MuiCheckbox-root.Mui-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}
.tableColumn.clcTh .MuiTableCell-root, .tableRow.clcTd .MuiTableCell-root {
    padding: 6px 12px;
    text-align: center;
}
.paginationCLC p{
  margin-bottom: 0;
  font-family: Montserrat;
}
.paginationCLC button{
  padding: 0 8px;
}
.paginationCLC svg{
  fill: #fff;
  cursor: pointer;
}
.paginationCLC .MuiTablePagination-toolbar{
  min-height: 28px;
}
.viewLeftCLC {
  color: #fff;
  font-family: "Montserrat";
  font-size: 12px;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
  padding-top: 5px;
}
.viewLeftCLC span{
  font-weight: 600;
  margin-left: 5px;
}