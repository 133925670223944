.detailDrawerDiv {
    font-family: Montserrat;

    .MuiPaper-root {
        background-color: #24263a;
        // min-height: 100vh;
    }

    .standardBox {
        width: 40vw;
        // min-height: 100%;
        // height:max-content;
        // background-color: #24263a;

        .baseDiv {
            padding: 20px;
            padding-bottom: 0px;
            border-bottom: 1px solid grey;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        .clientDivTop {
            border-radius: 16px 16px 0px 0px !important;
        }

        .clientDivBottom {
            margin-top: 0px !important;
            border-radius: 0px 0px 16px 16px !important;

            .detailDivChild {
                text-align: left !important;
                width: 100% !important;
                margin-left: 7%;
            }
        }

        .noRadius {
            border-radius: 0px !important;
        }

        .clientList {
            padding: 40px;
            font-family: Montserrat;

            .listHeader {
                color: #c0c2d8;
                font-size: 12px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgb(58 68 86 / 60%);
            }

            .listDiv {
                border-bottom: 1px solid rgb(58 68 86 / 60%);
                margin-top: 10px;

                .listItem {
                    display: flex;
                    justify-content: space-between;

                    .lineNameText {
                        color: #9cc2ff;
                        font-size: 10px;
                        margin-bottom: 15px;
                    }

                    .lineCodeText {
                        color: #b1c2df;
                        font-size: 12px;
                        margin-bottom: 15px;
                    }
                }
            }
            .listDivMM {
                border-bottom: 1px solid rgb(58 68 86 / 60%);
                margin-top: 10px;
                .listItem {
                    // display: flex;


                    .lineNameText {
                        color: #9cc2ff;
                        font-size: 12px;
                        margin-bottom: 15px;
                        font-weight: bold;
                    }

                    .lineCodeText {
                        color: #b1c2df;
                        font-size: 12px;
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .userDetailDiv {
            background-color: #2d2f44;
            width: 90%;
            margin: 0 auto;
            // height: 70px;
            height: auto;
            margin-top: 20px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            padding: 10px;
            color: white;
            align-items: center;

            .detailDivChild {
                width: 49%;
                text-align: center;
                color: #98bcf8;
                padding: 8px;

                p {
                    font-size: 10px;
                    margin-bottom: 0rem;
                    line-height: 15px;
                }

                h6 {
                    font-size: 12px;
                    margin-top: 7px;
                }
            }

            .firstChild {
                border-right: 1px solid #414d62;
            }
        }

        .accordionDiv {
            width: 85%;
            margin: 0 auto;
            color: #c0c2d8;
            margin-top: 20px;
            font-family: Montserrat;

            h5 {
                font-size: 12px;
            }

            .MuiAccordion-root {
                background-color: #2b2d42;
                color: #dadcde;
                font-size: 14px;
                margin-bottom: 10px;
                box-shadow: none;
                border: 1px solid #2b2d42;
                border-radius: 10px;
                font-family: Montserrat;

                .MuiSvgIcon-root {
                    color: #dadcde;
                }

                .MuiAccordionSummary-content {
                    .MuiTypography-root {
                        font-family: Montserrat;
                        color: #dadcde;
                        font-size: 14px;
                    }
                }

                .MuiAccordionDetails-root {
                    padding-left: 30px;
                    padding-bottom: 0px;
                    padding-top: 0px;

                    .MuiTypography-root {
                        font-family: Montserrat;
                        color: #b1c2df;
                        font-size: 14px;
                        padding: 7px;
                        border-bottom: 0.5px solid rgb(93 151 246 / 20%);
                    }

                    .MuiTypography-root:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.detailDrawerCustomDiv {
    font-family: Montserrat;

    .MuiPaper-root {
        background-color: #24263a;
        // min-height: 100vh;
    }

    .standardBox {
        width: 90vw;
        // min-height: 100%;
        // height:max-content;
        // background-color: #24263a;

        .baseDiv {
            padding: 20px;
            padding-bottom: 0px;
            border-bottom: 1px solid grey;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        .clientDivTop {
            border-radius: 16px 16px 0px 0px !important;
        }

        .clientDivBottom {
            margin-top: 0px !important;
            border-radius: 0px 0px 16px 16px !important;

            .detailDivChild {
                text-align: left !important;
                width: 100% !important;
                margin-left: 7%;
            }
        }

        .noRadius {
            border-radius: 0px !important;
        }

        .clientList {
            padding: 40px;
            font-family: Montserrat;

            .listHeader {
                color: #c0c2d8;
                font-size: 12px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgb(58 68 86 / 60%);
            }

            .listDiv {
                border-bottom: 1px solid rgb(58 68 86 / 60%);
                margin-top: 10px;

                .listItem {
                    display: flex;
                    justify-content: space-between;

                    .lineNameText {
                        color: #9cc2ff;
                        font-size: 10px;
                        margin-bottom: 15px;
                    }

                    .lineCodeText {
                        color: #b1c2df;
                        font-size: 12px;
                        margin-bottom: 15px;
                    }
                }
            }
            .listDivMM {
                border-bottom: 1px solid rgb(58 68 86 / 60%);
                margin-top: 10px;
                .listItem {
                    // display: flex;


                    .lineNameText {
                        color: #9cc2ff;
                        font-size: 12px;
                        margin-bottom: 10px;
                        font-weight: bold;
                    }

                    .lineCodeText {
                        color: #b1c2df;
                        font-size: 12px;
                        margin-bottom: 10px;
                        font-weight: normal;
                    }
                }
            }
        }

        .userDetailDiv {
            background-color: #2d2f44;
            width: 90%;
            margin: 0 auto;
            // height: 70px;
            height: auto;
            margin-top: 20px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            padding: 10px;
            color: white;
            align-items: center;

            .detailDivChild {
                width: 49%;
                text-align: center;
                color: #98bcf8;
                padding: 8px;

                p {
                    font-size: 10px;
                    margin-bottom: 0rem;
                    line-height: 15px;
                }

                h6 {
                    font-size: 12px;
                    margin-top: 7px;
                }
            }

            .firstChild {
                border-right: 1px solid #414d62;
            }
        }

        .accordionDiv {
            width: 85%;
            margin: 0 auto;
            color: #c0c2d8;
            margin-top: 20px;
            font-family: Montserrat;

            h5 {
                font-size: 12px;
            }

            .MuiAccordion-root {
                background-color: #2b2d42;
                color: #dadcde;
                font-size: 14px;
                margin-bottom: 10px;
                box-shadow: none;
                border: 1px solid #2b2d42;
                border-radius: 10px;
                font-family: Montserrat;

                .MuiSvgIcon-root {
                    color: #dadcde;
                }

                .MuiAccordionSummary-content {
                    .MuiTypography-root {
                        font-family: Montserrat;
                        color: #dadcde;
                        font-size: 14px;
                    }
                }

                .MuiAccordionDetails-root {
                    padding-left: 30px;
                    padding-bottom: 0px;
                    padding-top: 0px;

                    .MuiTypography-root {
                        font-family: Montserrat;
                        color: #b1c2df;
                        font-size: 14px;
                        padding: 7px;
                        border-bottom: 0.5px solid rgb(93 151 246 / 20%);
                    }

                    .MuiTypography-root:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .detailDrawerDiv {
        .standardBox {
            width: 100vw;
        }
    }
}
.inspectionConfigHead .taskHeaders:nth-child(1), .inspectionConfigBody .taskLabel:nth-child(1){
    width: 10%;
    color: #c0c2d8;
    margin-top: 0;
    margin-left: 0;
    font-size: 12px;
    font-family: "Montserrat";
    line-height: 1;
}
.inspectionConfigHead .taskHeaders:nth-child(2), .inspectionConfigBody .taskLabel:nth-child(2){
    width: 30%;
    color: #c0c2d8;
    margin-top: 0;
    margin-left: 0;
    font-size: 12px;
    font-family: "Montserrat";
    line-height: 1;
}
.inspectionConfigHead .taskHeaders:nth-child(3), .inspectionConfigBody .taskLabel:nth-child(3){
    width: 10%;
    color: #c0c2d8;
    margin-top: 0;
    margin-left: 0;
    font-size: 12px;
    font-family: "Montserrat";
    line-height: 1;
}
.inspectionConfigHead .taskHeaders:nth-child(4), .inspectionConfigBody .taskLabel:nth-child(4){
    width: 50%;
    color: #c0c2d8;
    margin-top: 0;
    margin-left: 0;
    font-size: 12px;
    font-family: "Montserrat";
    line-height: 1;
}
.inspectionConfigHead .taskHeaders{
    margin-bottom: 0px;
    font-weight: 600;
}
.inspectionConfigBody > div{
    margin-bottom: 0;
    width: 100%;
}
.inspectionConfigBody .taskLabel{
    padding: 10px 0;
}
.inspectionConfigBody {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(58, 68, 86, 0.6);
}