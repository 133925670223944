.CMlabelDiv {
    margin-top: 10px;
    margin-bottom: 5px;
    span {
        margin: 10px;
    }
}
.innerCardGroup{
    padding: 10px 0 0 0;
    height: calc(100vh - 9rem);
}
.scrollCont{
    height: calc(100% - 0rem);
    overflow-y: scroll;
}
.MuiCard-root.inCardGroup{
    background: #1f1f1f;
    display: flex;
    justify-content: space-between;
    height: 10rem;
}
.inCardGroup p{
    color: #e0e0e0;
}
.inCardGroup:hover {
    box-shadow: 6px 6px 20px rgba(0,0,0,.2), -12px 12px 40px rgba(0,0,0,.3);
}
.threeOption button{
    background-color: #000;
    border-radius: 0;
    font-family: "Montserrat-Medium";
    color: #e0e0e0;
}
.threeOption button:hover{
    background-color: #252525;
    opacity: 1;
}
.svgposition{
    position: relative;
    top: 0.83rem;
    right: 0.66rem;
}
.svgposition path{
    fill: #e0e0e0;
}
.wrapgroupcls{
    width: 100%;
    height: 100%;
}
.topGroupcls{
    max-width: 25%;
    margin-right: 1rem;
    float: left;
    background: #1f1f1f;
    box-shadow: -4px 4px 15px rgba(0,0,0,.1);
    border-radius: 4px;
    min-width: 24.25%;
    margin-bottom: 0.83rem;
    color: #e0e0e0; 
}
.wrapgroupcls .topGroupcls:nth-child(4n){
    margin-right: 0;
}
.all-labelcls h6{
    color: #e0e0e0;
    font-family: "Montserrat-Medium";
}
.all-labelcls input{
    color: #e0e0e0 !important;
    -webkit-text-fill-color: #e0e0e0 !important;
}
.all-labelcls fieldset{
border: 1px solid #5d5e60 !important;
}
.all-labelcls .MuiInputBase-root {
    height: 40px;
    margin-top: 0px;
    line-height: 1;
    font-size: 12px;
}
.butondrower .cancelBtn {
    font-size: 1.16rem;
    color: #e0e0e0;
    background: #2f2f2f;
    border-color: #2f2f2f;
    box-shadow: none;
    border-radius: 6px;
    height: 3.33rem;
    width: 8.33rem;
    float: left;
    margin-right: 1.66rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    font-family: "Montserrat-Medium";
    text-transform: capitalize;
}
.butondrower .cancelBtn:hover {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #3e3e3e!important;
    outline: 0;
    outline: 0 auto -webkit-focus-ring-color;
}
.butondrower .saveBtn {
    font-size: 1.16rem;
    font-family: "Montserrat-Medium";
    color: #000;
    background: #5d97f6;
    box-shadow: none;
    border-radius: 6px;
    height: 3.33rem;
    width: 8.33rem;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    text-transform: capitalize;
}
.butondrower .saveBtn.Mui-disabled{
color: #e0e0e0;
cursor: no-drop;
pointer-events: bounding-box;
font-size: 1.16rem;
font-family: "Montserrat-Medium";
color: #000;
background: #5d97f6;
}
.butondrower .saveBtn:hover{
    color: #000;
    background-color: #6aa2ff;
    border-color: #6aa2ff;
}
.deletepopupbox.MuiBox-root{
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 9;
    background: #1f1f1f;
    border-radius: 4px;
    box-shadow: none;
    border: 0;
    padding: 0;
}
.deletepopupbox .titleDiv {
    margin: 0;
    padding: 0;
    font-size: 1.33rem;
    color: #e0e0e0;
    font-family: "Montserrat-Medium";
        height: 5rem;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 0 2rem;
}
.bbt {
    border-bottom: 1px solid #313131;
}
.deletepopupbox p, .deletepopupbox h2{
    color: #e0e0e0;
    font-family: "Montserrat-Regular";
}
.deletepopupbox p{
    margin-bottom: 40px;
}
.padwraps{
    padding: 2rem;
}
.radiolabls{
    color: #e0e0e0;
}
.radiolabls path{
    fill: #e0e0e0;
}
.radiolabls .Mui-checked path{
    fill: #5d97f6;
}
// .MuiMenuItem-root:hover{
// color: #1f1f1f;
// }

[aria-labelledby="demo-simple-select-label-load"] li,
.MuiAutocomplete-popper .MuiAutocomplete-listbox {
    font-size: .83rem;
    color: #fff;
    font-family: "Montserrat";
}
[aria-labelledby="demo-simple-select-label-load"] li:hover,
.MuiAutocomplete-option[data-focus=true] {
    color: #5d97f6;
    font-size: .83rem;
    font-family: "Montserrat-Regular";
    text-decoration: none;
    background-color: #2a2c2d!important;
}
.textareaheight .MuiInputBase-root, .multilinetxt .MuiInputBase-root {
    color: #ffff !important;
    height: 6rem !important;
    margin: 0px !important;
}
.user_demo {
    background: #1d1e2c !important;
    padding: 5px 0px 5px 5px;
    border-radius: 4px;
}
/* .MuiList-root.MuiList-padding li{
    background-color: #000 !important;
    color: #e0e0e0 !important;
    font-family: "Montserrat-Medium" !important;
} */
.user_demo li p {
    color: #e0e0e0;
}
.user_demo ul{
    overflow-y: scroll;
    max-height: 180px;
    height: 100%;
}
.user_demo li button path {
    fill: #e0e0e0;
}
.multilineheight .MuiInputBase-colorPrimary{
    max-height: 80px !important;
    height: auto !important;
    min-height: 40px;
}
#menu- .MuiSvgIcon-fontSizeMedium{
    fill: #b1c2df;
}
.MuiTooltip-popperArrow.MuiTooltip-popper {
    z-index: 15001;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipArrow{
    background-color: #1d1e2c;
}
.MuiTooltip-arrow::before {
    background-color: #1d1e2c !important;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    margin-top: 0px;
    height: 0.8em;
    width: 0.8em;
}
.MuiSelect-select span {
        padding: 3px 0px !important;
    }
    .MuiSelect-multiple{
        min-height: auto !important;
    }
.radiolabls .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    margin-top: 0px;
    height: 0.8em;
    width: 0.8em;
}
.mtop{
    margin-top: 8px !important;
}
.mabtop{
    margin-top: 14px !important;
}
.mbtop{
    margin-top: 10px;
}
.MuiTypography-root, .MuiButtonBase-root, .MuiInputBase-root{
    font-family: Montserrat !important;
}
.MuiInputBase-root input{
    padding-top: 0;
    padding-bottom: 0;
    height: 23px;
}
.ruleGroup-header button{
    margin-bottom: 10px !important;
    padding: 0 10px!important;
}
.MuiButtonBase-root.MuiMenuItem-gutters{
    font-size: 14px;
}
.ruleGroup-body .MuiSelect-icon{
fill: #e0e0e0;
}
.ruleGroup-body .MuiInput-underline:before {
    border-bottom: 1px solid #e0e0e0 !important;
}
.ruleGroup-body .MuiInput-underline, .MuiAutocomplete-noOptions{
    color: #e0e0e0!important;
    font-family: Montserrat !important;
}
#checkboxes-tags-demo-listbox li .MuiSvgIcon-root {
    fill: #e0e0e0;
}
.tooltiptxt{
    padding: 15px;
    line-height: 1.5;
}
.taskLabel {
    width: 80px;
    margin-top: 10px;
    margin-bottom: 5px;
    span {
        margin: 10px;
    }
}
.taskDesc {
    width: 200px;
    word-wrap: break-word;
    margin-top: 10px;
    margin-bottom: 5px;
    span {
        margin: 10px;
    }  
}
.taskHeaders {
    color: #ffffff;
    margin-bottom: 20px;
}
.MuiFormControlLabel-root.textfont .MuiFormControlLabel-label {
    font-size: 14px;
}