

.loader {
    color: white;
    text-align: center;
}

.nodataRowAlerts{
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.no_text {
    color: white;
}
.container_table.sopdash{
    padding-left: 3px;
    padding-right: 3px;
}