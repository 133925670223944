.datetofrom{
    background-color: #121212;
    padding: 6px 5px 2px 5px;
    color: #e0e0e0;
}
.datetofrom input[type='date']{
    color: #e0e0e0;
}
.datetofrom input[type='date']:first-child{
    margin-right: 10px;
}
.datetofrom .MuiInput-underline:before,
.datetofrom .MuiInput-underline:after{
    border: 0px;
}
.datetofrom p{
    line-height: 1.7;
}
.popupuploaded .MuiDialog-paper{
background-color: #121212;
}
.popupuploaded .MuiDialogTitle-root{
color: #e0e0e0;
}
.datetofrom button{
    padding: 0px;
    position: relative;
    top: -2px;
    min-width: 24px;
}

.dateuichangetop .MuiInput-formControl {
    border: 1px solid #5d5e60;
    border-radius: 4px;
    padding: 0 5px;
}
.dateuichangetop .MuiInput-underline:before,
.dateuichangetop .MuiInput-underline:hover:not(.Mui-disabled):before,
.dateuichangetop .MuiInput-underline:after{
    border: 0px;
}
.addremovcls button.Mui-disabled{
    opacity: 0;
}
.no-spinners input[type="number"]::-webkit-inner-spin-button,
   .no-spinners input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
}
.plantNameTA .MuiInputBase-colorPrimary{
    border-radius: 10px;
}
.fontTxt svg text{
    font-size: 14px;
}
.radiolabls.labcolor .MuiTypography-root{
    color: #e0e0e0 !important;
}
.colorlab svg{ fill: #fff;}
/* For Graph UI CSS Start */
.dashboarGraph{
    background-color: #24263a;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
}
/* For Graph UI CSS End */
.colorlab svg, .arrowColors svg{ fill: #e0e0e0;}
.createReport.clc-cls{
padding-left: 20px;
padding-right: 20px;
}
.clclabel{
    min-height: 450px;
}
.tablemapping .tableRow .MuiTableCell-root{
    padding: 6px 10px;
}
.tablemapping .tableRow .MuiTableCell-root .inputDiv .Mui-readOnly{
    background-color: #2e304a;
}
.tablemapping .tableRow .MuiTableCell-root .inputDiv input{
    font-size: 12px;
    font-weight: normal;
    padding: 0 10px;
    width: 100px;
}
.tablemapping .tableRow td.MuiTableCell-root:nth-child(1) .inputDiv input,
.tablemapping .tableRow td.MuiTableCell-root:nth-child(2) .inputDiv input {
    width: 300px;
}
.tablemappingLimit .tableRow .MuiTableCell-root{
    padding: 6px 10px;
}
.tablemappingLimit .tableRow .MuiTableCell-root .inputDiv .Mui-readOnly{
    background-color: #2e304a;
}
.tablemappingLimit .tableRow .MuiTableCell-root .inputDiv input{
    font-size: 12px;
    font-weight: normal;
    padding: 0 10px;
}
.tablemappingLimit .tableRow td.MuiTableCell-root:nth-child(1) .inputDiv input{
    width: 350px;
}
.tablemappingLimit .tableRow td.MuiTableCell-root:nth-child(2) .inputDiv {
    width: 300px;
}