.cardContainer {
    padding-left: 3px;
    padding-right: 3px;
    height: 300px;
}

.cardStyle {
    border-radius: 0px;
    background-color: #422b2b;
    overflow: inherit;
    padding: 10px; /* Optional padding inside the card */
    height: 500px;
    display: flex;
    gap: 10px;
}

.divStyle {
    height: 350px;
    width: 50%;
    background-color: #3b3f58; /* Background color for the first div */
    margin-bottom: 10px; /* Gap between divs */
}

.divStyleSecond {
    height: 350px;
    width: 50%;
    background-color: #4e5c76; /* Background color for the second div */
}
