.MuiSnackbar-root{
    // left: 30% !important;
    // top: 72px !important;
    left: 0 !important;
    top: 72px !important;
    right: 0 !important;
}
.filterDropdownForCpp .MuiSnackbar-root{
    top: 6px !important;  
}
.acgSnackbar {
    font-family: 'Montserrat';
    z-index: 999999 !important;
   
}
.login-snackbaracgSnackbar, .acgSnackbar, .alertmsgdisplayacgSnackbar{
    transform: translateX(0%) !important;
}