.drawerContent {
    margin-top: 10px;
    margin-bottom: 35px;
}
.drawerContent .searchBox:before {
    border-bottom: none !important;
    transition: none !important;
}

.drawerContent .searchBox {
    border: 1px solid #2b2d42;
    background-color: #1d1e2c !important;
    width: 88% !important;
    color: #b1c2df;
    right: 5% !important;
    margin-top: 4px;
}

.drawerContent .searchbox:hover {
    border-bottom: none !important;
    background-color: white;
}

.drawerContent .searchBox:after {
    border-bottom: none !important;
    transition: none !important;
}
.drawerContent .searchBox input {
    font-size: 13px;
    font-family: Montserrat;
}
.drawerContent .searchBoxIcon {
    font-size: 20px;
}
.drawerContent .searchBoxIcon svg {
    color: #dbdfea !important;
}
.drawerContent .searchBoxIconCancel svg {
    color: #dadcee !important;
}
.drawerContent .searchBoxIconCancel {
    position: absolute;
    right: 10px;
    cursor: pointer;
}
