
.profileDropDown {
    // background-color: #24263a !important;
    .MuiPaper-root {
        background-color: #24263a !important;
        .actionmenu {
            color: white;
            p {
                font-family: Montserrat;
                font-size: 12px;
            }
            font-family: Montserrat;
        }
    }
    .MuiPaper-root .MuiButtonBase-root:not(:last-child) {
        border-bottom: 1px solid #3a4456;
    }
    // .MuiPaper-root .MuiSwitch-root {
    .MuiPaper-root .MuiButtonBase-root .MuiSwitch-switchBase {
        border-bottom: none;
    }
    // }
}
.Navigation {
    .badge-custom-css {
        .MuiBadge-badge {
            position: absolute;
            transform: translate(-7px, 10px);
            // background-color: white;
            // color: black;
            // font-family: 'Montserrat';
            // font-size: 15px;
            // font-weight: 600;
        }
    }
    .navLabel {
        // margin-left: px;
        padding: 10px;
        text-transform: capitalize;
    }
    .appbar {
        // background-color: transparent;
        // background: linear-gradient(90deg, transparent 5%, #1d1e2c 5%);
        // background: linear-gradient(90deg, #24263a 0%, #1d1e2c 14%);
        // background: linear-gradient(90deg, #24263a 4.7%, #1d1e2c 0%);
        // background: linear-gradient(90deg, transparent 4%, #1d1e2c 6%);
        border-bottom: 1px solid rgb(43, 45, 66) !important;
        box-shadow: none;
        border-bottom: none;
        z-index: 9999;
        // transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        .profileMenuDiv {
            display: flex;
            color: white;
            margin-left: 10px;
            font-family: Montserrat !important;
            align-items: center;
        }
    }
    .openedAppbar {
        // background: linear-gradient(90deg, #24263a 0%, #1d1e2c 0%);
        // transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        z-index: 99;
    }
    .divider {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .openableDrawer {
        div {
            justify-content: start;
        }
        li .MuiButtonBase-root {
            margin-bottom: 2px;
        }
        .navigationlink {
            text-decoration: none;
            color: white;

            li {
                border-left: 4px solid transparent;
                span {
                    font-family: Montserrat;
                    font-size: 13px;
                }
            }
            img {
                width: 32px;
                height: 32px;
            }
        }
        .activeLink {
            li {
                border-left: 4px solid #5d97f6;
                .MuiButtonBase-root {
                    background: linear-gradient(89.97deg, rgba(93, 151, 246, 0.5) 0.45%, rgba(93, 151, 246, 0) 80.37%);
                }
            }
        }
        .MuiDrawer-paperAnchorLeft {
            background-color: #24263a;
            z-index: 1300;
            //   box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
            //     0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        }
        .MuiListItem-root .MuiListItemIcon-root,
        .MuiListItemText-root {
            color: white;
        }
    }
    .opened .MuiDrawer-paper {
        width: 252px;
        // transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
}
.profileDropDown.notifybox ul.MuiList-root{
margin: 0px;
padding: 0px;
position: relative;
}
.profileDropDown.notifybox .MuiPaper-root{
    padding-bottom: 15px;
}
.notificationCount {
    position: absolute;
    top: -10px;
    right: -5px;
    border-radius: 50%;
    padding: 4px 2px;
    font-weight: bold;
    height: 16px;
    width: 20px;
    text-align: center;
    color: #fff;
    font-size: 9px;
    line-height: 1;
    background: #ff0000;
}
.mainTitle{
    font-size: 14px;
    background-color: #191919;
    border-bottom: 1px solid #2b2d42;
    color: #fff;
    padding: 8px 15px;
    margin: 0;
}
.mainTitle span{
    float: right;
    cursor: pointer;
    color: #5d97f6;
}
.wrpnotify {
    padding: 0 15px;
    border-bottom: 1px solid #2b2d42;
}
.wrpnotifyTitle h2{
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    margin-top: 0.5rem;
}
.wrpnotifyTime {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    font-style: italic;
    margin-bottom: 10px;
    margin-top: 10px;
}
.machineserialno{
    margin-bottom: 5px;
}
.dateAcknowledge{
    color: #5d97f6;
    cursor: pointer;
}
.cardnotify{
    background-color: #24263a;
    border: 1px solid #24263a;
    border-radius: 6px;
    box-shadow: none;
    min-height: 340px;
    width: 100%;
    line-height: 1;
    padding: 20px;
    text-transform: capitalize;
    font-size: 12px;
    position: relative;
}
.radiobtnnotify {
    position: absolute;
    width: 85%;
    bottom: 20px;
}
.popupcontents .alertcls span{
    display: inline-block;
    min-width: 110px;
}
.popupcontents .alertcls.cmnColmn{
display: flex;
}
.popupcontents .alertcls.cmnColmn em{
    font-style: normal;
}
.alertcls{
    color: rgba(177, 194, 223, 0.8);
    margin-bottom: 12px;
}
.recomendedOption{
    margin: 0;
    padding: 0;   
  }
  .recomendedOption li{
    list-style: circle;
  }
.radiobtnnotify .actionbutons {
    margin-bottom: 0px;
}
.alert_title span, .alert_source span, .dtei_link span, .machine_serialno span{
    // min-width: 100px;
    // display: inline-block;
}
.actionbutons{
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}
.actualTable th span{
    line-height: 1;
    min-height: 26px;
}
.cardnotify.criticalCls {
	border: 1px solid #ff0000;
}
.cardnotify.majorCls {
	border: 1px solid #ffbf00;
}
.cardnotify.minorCls {
	border: 1px solid #00ff00;
}
.cardnotify.veryHighCls {
    border: 1px solid #ffa500;
}
.cardnotify.highCls {
    border: 1px solid #ff0000;
}
.cardnotify.mediumCls {
    border: 1px solid #ffbf00;
}
.cardnotify.veryLowCls {
    border: 1px solid #00ff00;
} 
.cardnotify.lowCls{
    border: 1px solid #0000ff;
}
// .criticalsevirity{
//     color: #ff0000;
// }
// .majorsevirity{
//     color: #DAFF33;
// }
// .minorsevirity{
//     color: #33FF8A;
// }
.cardnotify.criticalCls .alert_severity span:last-child{
    color: #ff0000;
}
.cardnotify.majorCls .alert_severity span:last-child{
    color: #ffbf00;
}
.cardnotify.minorCls .alert_severity span:last-child{
    color: #00ff00;
}
.cardnotify.veryHighCls .alert_severity span:last-child{
    color: #ffa500;
}
.cardnotify.highCls .alert_severity span:last-child{
    color: #ff0000;
}
.cardnotify.mediumCls .alert_severity span:last-child{
    color: #ffbf00;
}
.cardnotify.veryLowCls .alert_severity span:last-child{
    color: #00ff00;
} 
.cardnotify.lowCls .alert_severity span:last-child{
    color: #0000ff;
}
.recom{
    min-height: 30px;
}
.actionbutons button{
    font-size: 12px;
    font-weight: 600;
    height: 24px;
    width: auto;
    box-shadow: none;
    border-radius: 8px;
    padding: 0 10px;
}
.acceptbtn{
    color: #000000;
    background: #5d97f6;
    border: 1px solid #5d97f6;
    cursor: pointer;
}
.rejectbtn{
    margin-right: 28px;
    color: #5d97f6;
    border: 1px solid #5d97f6;
    background-color: rgba(93, 151, 246, 0.08);
    cursor: pointer;
}
.alertcls span{
    padding-right: 5px;
    font-weight: 600;
}
.optone form {
	position: relative;
}  
.optone span.errorsown{
    color: rgb(240, 104, 104);
    position: absolute;
    font-size: 10px;
    bottom: -12px;
}
.textboxcol {
    margin-top: 0px;
}
.actiontkn{
    margin-bottom: 0px;
}
.radiobtnnotify input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #1d1e2c inset !important;
}
.radiobtnnotify ::placeholder {
    color: #e0e0e0!important;
  }
.textboxcol .MuiInputBase-root input{
color: #fff;
font-size: 12px;
height: 32px;
}
.textboxcol .MuiOutlinedInput-root{
    height: 32px !important;
}
.textboxcol fieldset{
   border: 1px solid #645555 !important; 
}

.detaillinks{
    cursor: pointer;
    color: #5d97f6;
}
.radiobtnnotify label{
    color: rgba(177, 194, 223, 0.8);
    font-size: 14px;
    margin-right: 20px;
    margin-top: 10px;
  }
.radiobtnnotify input[type="radio"] {
    margin-right: 5px;
    cursor: pointer;
}
.radiobtnnotify .textboxcol label {
    margin-top: 10px;
    margin-bottom: 10px;
}
  .alertcls.alert_plantname {
    display: flex;
}
  .alertcls.alert_plantname span {
    width: 100px;
}
.base-Div {
    width: 600px;
    height: auto;
}
.MuiDialogContent-root.customtopcls{
    padding: 0;
}
.contentmodel table th{
    background-color: #2e304a;
    border-bottom: none;
    color: #c0c2d8;
    font-family: Montserrat;
    font-size: 13px;
    padding: 6px 15px;
    position: relative;
}
.contentmodel table td{
    color: #e0e0e0;
    padding: 10px 15px;
    line-height: 1;
    font-family: Montserrat;
    font-size: 12px;
    text-align: left;
    border-bottom: 1px solid grey;
}
.popupcontents {
    padding: 0 15px;
    margin-top: 20px;
    font-size: 12px;
}
@media (max-width: 767px) {
    .Navigation {
        .appbar {
            background-color: #24263a;
            .profileMenuDiv {
                display: none;
            }
        }
        .openableDrawer .MuiDrawer-paper {
            width: 0px;
            // transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        }
        .opened .MuiDrawer-paper {
            width: 250px;
            transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        }
    }
}
