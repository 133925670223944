
.cpp-analysiscls .baseDiv {
        padding: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid grey;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
}
.mainarea{
    width: 100%;
    padding: 20px 0;
}
.leftcontent{
    float: left;
    width: 100%;
}
.cpp-filter-button {
    margin-top: 10;
}
.filtermenu {
    float: right;
    width: 24%;
    margin-left: 1%;
    padding: 15px;
    background-color: #2e304a;
}
.label_style {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin: 14px 0 5px 0;
    color: #c0c2d8;
}
.graph_div {
    float: left;
    width: 64%;
    background: #2e304a;
    height: 600px;
    padding: 10px;
}
.table_div{
    float: right;
    width: 35%;
    height: 600px
}
.powerBIDrawerDiv .table_div .scrollDiv .MuiPaper-root {
    min-height: 70vh;
}
.table_div .scrollDiv .MuiPaper-root th{
    font-size: 12px;
    padding: 8px 6px;
    font-family: 'Montserrat';
    border: 1px solid #898ba726;
    line-height: 16px;
}
.table_div .scrollDiv .MuiPaper-root th:nth-child(2),
.table_div .scrollDiv .MuiPaper-root th:nth-child(3),
.table_div .scrollDiv .MuiPaper-root th:nth-child(4),
.table_div .scrollDiv .MuiPaper-root th:nth-child(5),
.table_div .scrollDiv .MuiPaper-root th:nth-child(6),
.table_div .scrollDiv .MuiPaper-root tbody td:nth-child(2),
.table_div .scrollDiv .MuiPaper-root tbody td:nth-child(3),
.table_div .scrollDiv .MuiPaper-root tbody td:nth-child(4),
.table_div .scrollDiv .MuiPaper-root tbody td:nth-child(5),
.table_div .scrollDiv .MuiPaper-root tbody td:nth-child(6) {
    min-width: 100px;
}
.table_div .scrollDiv .MuiPaper-root tbody td{
    font-size: 12px;
    padding: 0px 6px;
    height: 40px;
    color: #e0e0e0;
    font-family: 'Montserrat';
    border: 1px solid #898ba726;
}

.selectparm {
    display: flex;;
    // align-items: center;
    color: #e0e0e0;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat";
    align-items: center;
}
.dropparam {
    // justify-content: space-between;
    // display: flex;
}
.dropparam .acgDropdown .dropdownbx {
    margin-left: 10px;
    height: 40px;
    border-radius: 4px;
}
.selectparm label{
    margin-bottom: 0;
    width: 25%;

}
.dropparam .acgDropdown{
    margin-left: 10px;
    margin-right: 10px;
}
.buttonwrap{
    display: flex;
    justify-content: space-between;
}
.mainarea .firstgraphWrap .table_div{
    height: 525px !important;
}
.mainarea .secondgraphWrap .table_div{
    height: 565px !important;
}
.graph_div.firstgraph{
    height: 525px !important;
}
.graphcontent{
    height: 400px !important;
    width: 100%;
    background-color: #24263a !important;
    margin: 10px 0;
    padding: 10px;
}
.mbtopcanvas canvas{
    margin-top: 0 !important;
}
.graph_div canvas {
    width: 100% !important;
}
.makeStyles-optionStyle-7{
    font-size: 12px !important;
}

.cppFilter{
    display: flex;
    justify-content: right;
    gap: 15px;
    width: 75%;
}
.filterDropdownForCpp .acgDropdown{
    min-width: 165px!important;
}
.cppFilterSecChart{
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}

.radiocpp{
    color: #fff;
    font-size: 12px;
    line-height: 1;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    margin-top: 5px;
    gap: 7px;
}
.radiocpp input{
    cursor: pointer;
}
.radiocpp label{
    display: flex;
    gap: 4px;
    align-items: center;
}

.filterDropdownForCpp{
    width: 165px;
}

.cppFilterSecChart .filterDropdownForCpp{
    width: 168px;
}

.buttonwrap.cppbutons .MuiButtonBase-root.Mui-disabled {
    font-size: 11px;
    height: 24px;
    min-width: 72px;
    padding: 0 !important;
}
.tableToolbarCPP .filter {
    padding: 7px 15px;
    border: 1px solid #5d97f6;
    border-radius: 6px;
    background-color: rgba(93, 151, 246, 0.08);
    cursor: pointer;
    display: flex;
}
.tableToolbarCPP .filter span {
    color: #5d97f6;
    display: flex;
    font-family: Montserrat !important;
    font-size: 14px;
    font-weight: 600;
    height: 19px;
    width: 37px;
}


.secondgraph {
    height: 565px !important;
    margin-bottom: 20px;
}
