//Primary color codes(indigo)
$p050Bg: #e8eaf6;
$p050BgTxt: black;
$p100Bg: #c5cae9;
$p100BgTxt: black;
$p200Bg: #9fa8da;
$p200BgTxt: black;
$p300Bg: #7986cb;
$p300BgTxt: black;
$p400Bg: #5c6bc0;
$p400BgTxt: white;
$p500Bg: #3f51b5;
$p500BgTxt: white;
$p600Bg: #3949ab;
$p600BgTxt: white;
$p700Bg: #303f9f;
$p700BgTxt: white;
$p800Bg: #283593;
$p800BgTxt: white;
$p900Bg: #1a237e;
$p900BgTxt: white;
/*Secondary variables ($bgray) */
$s050bg: #eceff1;
$s050bgtxt: black;
$s100bg: #cfd8dc;
$s100bgtxt: black;
$s200bg: #b0bec5;
$s200bgtxt: black;
$s300bg: #90a4ae;
$s300bgtxt: black;
$s400bg: #78909c;
$s400bgtxt: black;
$s500bg: #607d8b;
$s500bgtxt: black;
$s600bg: #546e7a;
$s600bgtxt: white;
$s700bg: #455a64;
$s700bgtxt: white;
$s800bg: #37474f;
$s800bgtxt: white;
$s900bg: #263238;
$s900bgtxt: white;
//common colors and color overrides for primary and secondary colors
$white: #ffffff;
$black: #000000;
$error: #ff001f;
$red: #ff464a;
$grayLabel: #02122c;
$lightCyan: #a6cdca;
$plum: #ceacdc;
$cyan: #a2d2d9;
$darkenGray: #9c9ea0;
$orange: #ff974f;
$buttonBorder: #7c7c7b;
$primary: #0f249d;
$success: #2ca100;
$transparent: rgba(0, 0, 0, 0);
$green: #00bf6d;
$lightGreenBac: #c5f8e3;
$p050Bg: #b1ccda;
$p800Bg: #161a93;
$p900Bg: #02122c;
$p700Bg: #25243b;

$s050Bg: #f7f7f9;
$s100Bg: #ccd0d5;
$s200Bg: #8b929c;
$s300Bg: #9c9ea0;
$s400Bg: #d6dae7;
$s800Bg: #3e444a;
$s900Bg: #19253a;
