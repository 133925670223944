.machinemonitoringDrawer{
    
}
.machineMonitoringDrawerDiv {
    font-family: Montserrat;

    .MuiPaper-root {
        background-color: #24263a;
        // min-height: 100vh;
    }

    .offlineMainDiv {
        border: 1px dashed #4a566c;
        text-align: center;
        border-radius: 10px;
        height: 40px;
        padding-top: 8px;
        width: 20%;
        margin: 0 auto;
        font-size: 11px;
        color: #8d8f91;

        .offlineDiv {
            margin-bottom: 0px;

            .offline {
                color: #8d8f91;
                margin-right: 5px;
                margin-top: -2px;
            }
        }
    }

    .machineButton {
        text-align: 'center';
        text-align: center;
        min-width: 100px;
        font-family: 'Montserrat';
        font-size: 14px;
        line-height: normal;

    }

    .onlineMainDiv {
        border: 1px dashed #4a566c;
        text-align: center;
        border-radius: 10px;
        height: 40px;
        padding-top: 8px;
        width: 25%;
        /* margin: 0 auto; */
        font-size: 11px;
        color: #37bb52;
        margin-left: 5%;
        margin-top: 20px;

        .onlineDiv {
            color: #37bb52;
            margin-bottom: 0px;

            .online {
                color: #37bb52;
                margin-right: 5px;
                margin-top: -2px;
            }
        }
    }

    .phoneMainDiv {
        //   border: 1px solid #5d97f6;
        //   text-align: left;
        //  border-radius: 20px;
        height: 40px;
        padding: 10px;
        /* margin: 0 auto; */
        //   font-size: 13px;
        //  color: #5d97f6;
        margin-left: 1%;
        margin-top: 10px;

        .phoneDiv {
            color: #5d97f6;
            margin-bottom: 0px;

            .phone {
                border: 1px solid #5d97f6;
                border-radius: 50px;
                color: #5d97f6;
                margin-right: 5px;
                margin-top: -2px;
                margin-left: 8px;
                font-size: 18px;
                text-align: center;
            }
        }
    }

    .machineDrawerSearch {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 20px;

        .searchBox {
            border: 1px solid #2b2d42;
            background-color: #1d1e2c !important;
            width: 22% !important;
            color: #b1c2df;
            right: 1% !important;
            margin-top: -17px;
        }
    }

    .standardBox {
        width: 94vw;
        // min-height: 100%;
        // height:max-content;
        // background-color: #24263a;

        .baseDiv {
            padding: 20px;
            padding-bottom: 0px;
            border-bottom: 1px solid grey;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        .clientDivTop {
            border-radius: 16px 16px 0px 0px !important;
        }

        .clientDivBottom {
            margin-top: 0px !important;
            border-radius: 0px 0px 16px 16px !important;

            .detailDivChild {
                text-align: left !important;
                width: 100% !important;
                margin-left: 7%;
            }
        }

        .clientList {
            padding: 40px;
            font-family: Montserrat;

            .listHeader {
                color: #c0c2d8;
                font-size: 12px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgb(58 68 86 / 60%);
            }

            .listDiv {
                border-bottom: 1px solid rgb(58 68 86 / 60%);
                margin-top: 10px;

                .listItem {
                    display: flex;
                    justify-content: space-between;

                    .lineNameText {
                        color: #9cc2ff;
                        font-size: 10px;
                        margin-bottom: 15px;
                    }

                    .lineCodeText {
                        color: #b1c2df;
                        font-size: 12px;
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .userDetailDiv {
            background-color: #2d2f44;
            width: 90%;
            margin: 0 auto;
            min-height: 70px;
            height: auto;
            margin-top: 20px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            padding: 10px;
            color: white;

            .detailDivChild {
                width: 49%;
                text-align: center;
                color: #98bcf8;
                padding: 8px;

                p {
                    font-size: 10px;
                    margin-bottom: 0rem;
                    line-height: 15px;
                }

                h6 {
                    font-size: 12px;
                    margin-top: 7px;
                }
            }

            .firstChild {
                border-right: 1px solid #414d62;
            }
        }

        .accordionDiv {
            width: 85%;
            margin: 0 auto;
            color: #c0c2d8;
            margin-top: 20px;
            font-family: Montserrat;

            h5 {
                font-size: 12px;
            }

            .MuiAccordion-root {
                background-color: #2b2d42;
                color: #dadcde;
                font-size: 14px;
                margin-bottom: 10px;
                box-shadow: none;
                border: 1px solid #2b2d42;
                border-radius: 10px;
                font-family: Montserrat;

                .MuiSvgIcon-root {
                    color: #dadcde;
                }

                .MuiAccordionSummary-content {
                    .MuiTypography-root {
                        font-family: Montserrat;
                        color: #dadcde;
                        font-size: 14px;
                    }
                }

                .MuiAccordionDetails-root {
                    padding-left: 30px;
                    padding-bottom: 0px;
                    padding-top: 0px;

                    .MuiTypography-root {
                        font-family: Montserrat;
                        color: #b1c2df;
                        font-size: 14px;
                        padding: 7px;
                        border-bottom: 0.5px solid rgb(93 151 246 / 20%);
                    }

                    .MuiTypography-root:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .machineMonitoringDrawerDiv {
        .standardBox {
            width: 100vw;
        }
    }
}