.MachineListCard {
    min-height: 242px;
    background-color: #1d1e2c !important;
    border: 1px solid #2b2d42 !important;
    box-shadow: none !important;
    border-radius: 6px !important;

    .machineMainGrid {
        position: relative;
        margin-left: 11px;
        margin-top: 11px;
        .borderRight {
            width: 25%;
            height: 34px;
            border-right: 1px solid rgba(137, 139, 167, 0.2);
            color: #fff;
            position: absolute;
            top: 11%;
            // opacity: 0.2;
        }
        .heading {
            font-size: 10px;
            color: #9cc2ff;
        }
        .para {
            font-size: 12px;
            color: #b1c2df;
        }
    }

    .labelDiv {
        margin: 5px;
        span {
            margin: 5px;
        }
    }
    //   .online {
    //     color: #37bb52;
    //   }
    //   .offline {
    //     color: #8d8f91;
    //   }
    .onlineDiv {
        margin-left: 10px;
        color: #37bb52;
        font-size: 11px;
        .online {
            color: #37bb52;
            margin-right: 5px;
            margin-top: -2px;
        }
    }
    .offlineMainDiv {
        border: 1px dashed #8d8f91;
        text-align: center;
        padding: 20px;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        color: #8d8f91;
        margin-top: 46px;
        margin-bottom: 20px;
        .offlineDiv {
            margin-bottom: 0px;
            .offline {
                color: #8d8f91;
                margin-right: 5px;
                margin-top: -2px;
            }
        }
    }
    .machineBlock {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        padding-bottom: 0px;
        min-height: 90px;
    }
    .title {
        color: #dadcee;
        font-size: 14px;
        cursor: pointer;
    }
    .line {
        color: #9cc2ff;
        font-size: 12px;
    }
    .paraGraph {
        color: rgb(177 194 223 / 80%);
        font-size: 12px;
        .location {
            margin-top: -8px;
            margin-right: 5px;
        }
        .time {
            margin-top: -2px;
            margin-right: 5px;
        }
    }
}
// @media(min-width:767px) and (max-width:1023px){
//   .MachineListCard{
//     width:80%;
//   }
// }
