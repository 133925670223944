body {
    #demo-multiple-checkbox {
        svg {
            color: white;
        }
    }

    .radio-font {
        label {
            span {
                font-family: Montserrat;
            }
        }
    }

    [id*='menu-dropdown'] {
        z-index: 99999 !important;

        .MuiPaper-root {
            background-color: #1d1e2c !important;
            color: #b1c2df;
        }
    }

    [id*='country-menu'] {
        z-index: 99999 !important;
    }

    [id*='menu-dropdownMachineParams'] {
        .MuiSvgIcon-root {
            fill: white;
        }
    }

    [id*='menu-'] {
        z-index: 9999 !important;

        ul {
            background-color: #1d1e2c !important;
            color: #b1c2df !important;
            font-size: 12px;
        }
        ul:has(> li.ulSelectProduct),
        ul:has(> li.ulSelectProduct) li.Mui-selected:hover{
            background-color: rgba(25, 118, 210, 0.08) !important;
        }
        ul:has(> li.ulSelectProduct) li.Mui-selected{
            background-color: rgba(25, 118, 210, 0) !important;
        }
    }

    .MuiPickersPopper-root {
        z-index: 9999 !important;
    }

    .MuiPaper-root {
        background-color: #24263a;
    }

    .MuiAutocomplete-popper {
        z-index: 9999 !important;

        .MuiAutocomplete-paper {
            background-color: #1d1e2c !important;
            color: #b1c2df !important;
            font-size: 12px;
        }
    }

    .MuiCalendarOrClockPicker-root {
        -webkit-text-fill-color: white !important;

        svg {
            color: white !important;
        }
    }

    .hoverCard,
    .searchMachineCard {
        z-index: 9999 !important;
    }

    .MuiCalendarOrClockPicker-root {
        background-color: #24263a;
        color: #b1c2df;
    }

    // .makeStyles-modal-2{
    //     z-index: 9999 !important;
    // }
    // [class*='makeStyles-modal'] {
    //     z-index: 9999 !important;
    // }
    margin: 0;
    font-family: Montserrat !important;

    .MuiPaper-root::-webkit-scrollbar {
        width: 0.3em;
    }

    .MuiPaper-root::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #24263a;
    }

    .MuiPaper-root::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    .customscrollBar::-webkit-scrollbar {
        width: 0.2em;
    }

    .customscrollBar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #24263a;
    }

    .customscrollBar::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
    .MuiInputBase-root::before{
        border: none !important;
    }
    .MuiInputBase-root::after{
        border: none !important;
    }
    
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root.Mui-disabled{
    color: #b1c2df !important;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled{
    color: #b1c2df !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: #b1c2df !important;
}
.css-1uvydh2.Mui-disabled{
    -webkit-text-fill-color: #b1c2df !important;
}
.css-slyssw.Mui-disabled{
    color: #b1c2df !important;
}
.css-1jaw3da .MuiFormControlLabel-label.Mui-disabled{
    color: #b1c2df !important;
}

button:focus {
    outline: none;
}

body::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #24263a;
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

//remove inside of arrows button
input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }
  
  //remove outsideof arrows button
  input[type="number"]::-webkit-outside-spin-button {
    display: none;
  }