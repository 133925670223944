// .dn-report-form {
//     font-family: 'Montserrat';
//     background-color: #1d1e2c;
//     width: 100%;
//     height: 100%;
//     .labelDiv {
//         margin-top: 25px;
//         margin-left: 25px;
//     }

//     .inputDiv {
//         margin-top: 7px;
//         height: 46px;
//         margin-left: 20px;
//         margin-right: 20px;
//     }

//     .longInputDiv {
//         margin-top: 7px;
//         height: 80px;
//     }

//     .btns {
//         display: flex;
//         flex-wrap: nowrap;
//         justify-content: flex-end;
//         margin: 30px 19px 0 0;

//         .first-btn {
//             margin-right: 28px;
//             margin-bottom: 10px;
//             width: 145px;
//             height: 44px;
//             font-weight: 600;
//             padding: 0px;
//         }

//         .second-btn {
//             width: 145px;
//             height: 44px;
//             font-weight: 600;
//             font-size: 14px !important;
//         }
//     }
// }

// @media (max-width: 767px) {
//     .report-form {
//         .noWrap {
//             flex-wrap: wrap !important;
//             justify-content: space-around;
//         }

//         .first-btn {
//             margin-right: 0px !important;
//         }
//     }
// }
.labelfixed.mbt .CMlabelDiv.label-style{
    margin-top: 10px;
    margin-bottom: 5px;
}
.labelfixed label{
    margin: 0;
    font-size: 12px;
}
.labelfixed label.MuiInputLabel-animated {
    font-size: 12px !important;
    // padding-left: 0 !important;
}
.labelfixed label.Mui-focused.MuiInputLabel-animated {
    transform: translate(14px, 16px) scale(1) !important;
}