.standardBox1 {
    width: 40vw;
    // min-height: 100%;
    // height:max-content;
    // background-color: #24263a;

    .baseDiv {
        padding: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid grey;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
}

.alm-dn-report-form1 {
    font-family: 'Montserrat';
    background-color: #1d1e2c;
    // width: 100%;
    // width: 40vw !important;
    height: 100%;
    padding-left: 19px;

    // padding: 24px;
    .detailForm {
        width: 99%;
        margin-left: -3%;
    }

    .labelDiv {
        margin-top: 25px;
        margin-left: 25px;
    }

    .inputDiv {
        margin-top: 7px;
        height: 46px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .longInputDiv {
        margin-top: 7px;
        height: 80px;
    }

    .btns {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        margin: 30px -18px 0 0;

        .first-btn {
            margin-right: 28px;
            margin-bottom: 10px;
            width: 145px;
            height: 44px;
            font-weight: 600;
            padding: 0px;
        }

        .second-btn {
            width: 145px;
            height: 44px;
            font-weight: 600;
            font-size: 14px !important;
        }
    }
}

@media (max-width: 767px) {
    .report-form {
        .noWrap {
            flex-wrap: wrap !important;
            justify-content: space-around;
        }

        .first-btn {
            margin-right: 0px !important;
        }
    }
}