.machineMonitoring {
  margin-top: 50px;

  h4 {
    color: white;
  }

  .gridContainer {
    padding-left: 3px;
    padding-right: 3px;

    .gridContainerCard {
      min-height: 660px;
      border-radius: 10px 10px 0px 0px;
      background-color: #2b2d42 !important;
      overflow: inherit;

      .topDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid rgb(137 139 167 / 30%);

        .childDiv {
          h5 {
            margin-bottom: 1px;
            color: #dadcee;
            font-size: 16px;
            font-family: Montserrat;
          }

          p {
            margin-bottom: 10px;
            color: #9cc2ff;
            font-size: 12px;
            font-family: Montserrat;
          }
        }

        .topDivButton {
          background-color: rgba(93, 151, 246, 0.08);
          border: 1px solid #5d97f6;
          font-size: 12px;
          text-transform: capitalize;
          height: 36px;
          border-radius: 6px;
        }
      }

      .bottomDiv {
        padding: 20px;
      }
    }
  }
}