.custom-date-time-picker {
    width: 100%;
    .MuiInputBase-root {
        background-color: #1d1e2c;
        border-radius: 10px;
        min-height: 40px;
    }
    .MuiInputBase-input,
    .MuiButtonBase-root {
        color: #b1c2df;
        width: 100%;
        border-color: #2b2d42;
        border-width: 1px;
        align-content: center;
        border-radius: 10px;
        justify-content: center;
        background-color: #1d1e2c;
        font-size: 12px;
    }
}
.custom-date-time-picker-popper {
    z-index: 999999999 !important;
}

.custom-date-time-picker-popper {
    .MuiCalendarPicker-root,
    .MuiPickersCalendarHeader-root,
    .MuiPickersFadeTransitionGroup-root,
    .MuiCalendarPicker-viewTransitionContainer,
    .MuiButtonBase-root {
        color: #b1c2df !important;
        border-color: #2b2d42;
        border-width: 1px;
        align-content: center;
        justify-content: center;
        background-color: #1d1e2c;
        .MuiDayPicker-weekDayLabel,
        .MuiDayPicker-weekContainer {
            color: #b1c2df !important;
        }
    }
}
