.editviewconfig input.MuiInputBase-input, .mapTable input.MuiInputBase-input{
    font-size: 12px;
    font-weight: normal;
    padding: 0 10px;
}
.editviewconfig .Mui-disabled, .mapTable .Mui-disabled, .mapTable .Mui-readOnly {
    background-color: #2e304a !important;  
}
.mapTable .tableRow td.MuiTableCell-root:nth-child(1) .inputDiv input {
    width: 350px;
}
.mapTable .tableRow td.MuiTableCell-root:nth-child(2) .inputDiv {
    width: 300px;
}
.mapTable .actualTable tbody td:nth-child(3) .inputDiv input, 
.mapTable .actualTable tbody td:nth-child(4) .inputDiv input {
    text-align: center;
}