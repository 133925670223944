.userBadge {
    .tableColumn .MuiTableCell-root {
        padding-left: 5px;
        // left: 0px !important;
        line-height: 1;
    }
    .MuiButtonBase-root,
    .MuiCheckbox-root {
        color: #b1c2df !important;
    }
}

.createRole {
    .w-100 {
        width: 100%;
    }
    .labelDiv {
        margin-top: 25px;
    }
    .inputDiv {
        margin-top: 7px;
        height: 46px;
    }
    .longInputDiv {
        margin-top: 7px;
        height: 80px;
    }
    .btns {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        margin: 30px 19px 0 0;
        .first-btn {
            margin-right: 28px;
            margin-bottom: 10px;
            width: 145px;
            height: 44px;
            font-weight: 600;
            padding: 0px;
        }
        .second-btn {
            width: 145px;
            height: 44px;
            font-weight: 600;
            font-size: 14px !important;
        }
    }
    .section-buttons {
        font-size: 14px;
        color: #b1c2df !important;
        .MuiRadio-root {
            color: #b1c2df;
        }
        .Mui-checked {
            color: #5d97f6;
        }
        .user-client-dropdown {
            max-height: 36px;
            max-width: 350px;
            font-size: 10px;
            color: #b1c2df;
        }
    }
    label {
        font-size: 12px;
        color: #c0c2d8;
    }
    .email-section {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
    }
    .radio-buttons {
        margin-top: 20px;
    }
    .detailDrawerDiv {
        display: flex;
        justify-content: center;
        font-family: Montserrat;

        .MuiPaper-root {
            background-color: #24263a;
            // min-height: 100vh;
        }

        .standardBox {
            width: 40vw;
            // min-height: 100%;
            // height:max-content;
            // background-color: #24263a;

            .baseDiv {
                padding: 20px;
                padding-bottom: 0px;
                border-bottom: 1px solid grey;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }

            .clientDivTop {
                border-radius: 16px 16px 0px 0px !important;
            }

            .clientDivBottom {
                margin-top: 0px !important;
                border-radius: 0px 0px 16px 16px !important;

                .detailDivChild {
                    text-align: left !important;
                    width: 100% !important;
                    margin-left: 7%;
                }
            }

            .clientList {
                padding: 20px;
                font-family: Montserrat;
                .listHeader {
                    color: #c0c2d8;
                    font-size: 12px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgb(58 68 86 / 60%);
                }
                .listDiv {
                    border-bottom: 1px solid rgb(58 68 86 / 60%);
                    margin-top: 10px;
                    .listItem {
                        display: flex;
                        justify-content: space-between;
                        .lineNameText {
                            color: #9cc2ff;
                            font-size: 10px;
                            margin-bottom: 15px;
                        }
                        .lineCodeText {
                            color: #b1c2df;
                            font-size: 12px;
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            .userDetailDiv {
                background-color: #2d2f44;
                width: 90%;
                margin: 0 auto;
                height: 70px;
                margin-top: 20px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                padding: 10px;
                color: white;

                .detailDivChild {
                    width: 49%;
                    text-align: center;
                    color: #98bcf8;
                    padding: 8px;

                    p {
                        font-size: 10px;
                        margin-bottom: 0rem;
                        line-height: 15px;
                    }

                    h6 {
                        font-size: 12px;
                        margin-top: 7px;
                    }
                }

                .firstChild {
                    border-right: 1px solid #414d62;
                }
            }

            .accordionDiv {
                width: 100%;
                margin: 0 auto;
                color: #c0c2d8;
                margin-top: 20px;
                font-family: Montserrat;

                h5 {
                    font-size: 12px;
                }

                .MuiAccordion-root {
                    background-color: #2b2d42;
                    color: #dadcde;
                    font-size: 14px;
                    margin-bottom: 10px;
                    box-shadow: none;
                    border: 1px solid #2b2d42;
                    border-radius: 10px;
                    font-family: Montserrat;

                    .MuiSvgIcon-root {
                        color: #dadcde;
                    }

                    .MuiAccordionSummary-content {
                        .MuiTypography-root {
                            font-family: Montserrat;
                            color: #dadcde;
                            font-size: 14px;
                        }
                    }

                    .MuiAccordionDetails-root {
                        padding-left: 30px;
                        padding-bottom: 0px;
                        padding-top: 0px;

                        .MuiTypography-root {
                            font-family: Montserrat;
                            color: #b1c2df;
                            font-size: 14px;
                            padding: 7px;
                            border-bottom: 0.5px solid rgb(93 151 246 / 20%);
                        }

                        .MuiTypography-root:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        .searchBoxClient {
            position: relative;
        }
        .drawerContent {
            margin-top: 0px;
            margin-bottom: 0px;
            .searchBox {
                width: 100% !important;
                right: 0% !important;
            }
        }
    }

    @media (max-width: 767px) {
        .detailDrawerDiv {
            .standardBox {
                width: 100vw;
            }
        }
    }
}

// .dashboard {
//   background-color: #1d1e2c;
//   min-height: 100vh;
//   margin-left: 60px;
//   padding-top: 25px;
//   padding-bottom: 20px;
// }

@media (max-width: 767px) {
    .createUser {
        .noWrap {
            flex-wrap: wrap !important;
            justify-content: space-around;
        }
        .first-btn {
            margin-right: 0px !important;
        }
    }
}
@media (max-width: 767px) {
    .dashboard {
        margin-left: 0px;
    }
}
