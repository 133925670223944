.clientBadge {
    .tableColumn .MuiTableCell-root {
        padding-left: 8px;
        // left: 0px !important;
        line-height: 1;
    }
    .MuiButtonBase-root, .MuiCheckbox-root {
        color: #b1c2df !important;
    }
}
