@import '../../../utilities/theme/_theme.scss';

.popOverButton {
    .MuiButton-label {
        justify-content: left;
    }
    // padding: 14px 0px 0px 10px !important;
    padding: 0px !important;
    margin-top: 0px;
    .MuiSvgIcon-root {
        color: #5d97f6 !important;
    }
    // background-color: white;
}
.popOverButton:hover {
    background-color: transparent !important;
}
.hoverCard {
    .menuItem {
        font-family: 'Montserrat';
        color: $s900Bg;
        font-weight: 600;
        font-size: 12px;
    }
    .infoItem {
        font-family: 'Montserrat';
        color: $s900Bg;
        font-weight: 600;
        font-size: 12px;
        text-transform: none;
    }
    .menuImage {
        height: 30px;
        width: 30px;
        margin: 0px 10px 0px -5px;
        object-fit: cover;
        border-radius: 50%;
    }
    .MuiPaper-root {
        border-radius: 10px !important;
        // background-color: $white !important;
        top: 90px !important;
        background-color: #24263a !important;
        // box-shadow: 0 2px 14px 0 #b3b9da !important;
        .MuiMenu-list > .MuiButtonBase-root {
            background-color: $white;
        }
        .MuiMenu-list > .MuiButtonBase-root:hover,
        .MuiButtonBase-root:visited {
            background-color: transparent !important;
        }
    }
}
