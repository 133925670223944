.dashboard {
    background-color: #1d1e2c;
    min-height: 100vh;
    margin-left: 60px;
    padding-top: 25px;
    padding-bottom: 20px;
  }
  
  .headinglabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #DADCEE;
  }
  .ErrorlabelDiv{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color:'#F06868';
    span {
        margin: 5px;
    }
  }
  //Create client
  .createClient {
    .w-100 {
      width: 100%;
    }
    .labelDiv {
          margin-top: 25px;
      }
      .inputDiv {
          margin-top: 7px;
          height: 46px;
      }
      .longInputDiv {
          margin-top: 7px;
          height: 80px;
      }
      .btns {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-end;
          margin: 30px 19px 0 0;
          .first-btn {
              margin-right: 28px;
              margin-bottom: 10px;
              width: 145px;
              height: 44px;
              font-weight: 600;
              padding: 0px;
          }
          .second-btn {
              width: 145px;
              height: 44px;
              font-weight: 600;
              font-size: 14px !important;
          }
      }
  }
  @media (max-width: 767px) {
      .createReport {
          .noWrap {
              flex-wrap: wrap !important;
              justify-content: space-around;
          }
          .first-btn {
              margin-right: 0px !important;
          }
      }
  }
  @media (max-width: 767px) {
    .dashboard {
      margin-left: 0px;
    }
  }