.filterDiv {
    .MuiPopover-root {
        z-index: 99999 !important;
    }
    .MuiPaper-root {
        background-color: #24263a;
    }
    .standardBox {
        width: 40vw;
    }
    .btn {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        margin: 30px 19px 0 0;
        .first-bttn {
            margin-right: 28px;
            margin-bottom: 10px;
            width: 145px;
            height: 44px;
            font-weight: 600;
            padding: 0px;
        }
        .second-bttn {
            width: 145px;
            height: 44px;
            font-weight: 600;
            font-size: 14px !important;
        }
    }
}
@media (max-width: 767px) {
    .filterDiv {
        .standardBox {
            width: 100vw;
        }
        .noWrap {
            flex-wrap: wrap !important;
            justify-content: space-around;
        }
        .first-bttn {
            margin-right: 0px !important;
        }
    }
}
