.treemapcls .recharts-wrapper{
    width: 100%!important;
}
.wraplegends{
    float: left;
    width:100%;
}
.divclstree{
    float: left;
    width: 100%;
    margin-right: 0px;
}
.namelegends{
    font-size: 10px;
    color: #e0e0e0;
}