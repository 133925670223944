#alarm_bar_div {
  margin-top: -40px !important;
  /* border: 2px solid red; */
}
.tooltip {
  background-color: white !important;
}
.tooltip-overlay {
  color: white !important;
  background-color: black !important;
}
.dataset rect {
  height: 29px !important;
}
#alarm_bar_container {
  padding-top: 5px;
  /* border: 1px solid red; */
}
.has-data {
  display: none !important;
}
.visavail-tooltip div.tooltip-overlay {
  width: 310px !important;
}
.visavail .xAxis text {
  fill: white !important;
}
.tick text {
  font-size: 12px !important;
}
.tooltip-overlay {
  /* display: none !important; */
  /* content: "red"; */
}
.rect_has_no_data {
  /* display: none !important; */
}
.rect_has_no_data_OB {
  fill: yellow !important;
  /* display: none !important; */
}
.rect_has_data {
  /* display: none !important; */
}

/* .redtest{
    color: 'red';
  } */

.rect_has_data {
  fill: #65C54F !important;
    
}
.rect_has_no_data {
  fill: #D71635 !important;
}

.rect_has_extra_data {
  fill: #FFA71F !important;
}

.visavail {
  width: 100%;
  padding-top: 30px;
}
.legend_text{
  color: #adadad;
  font-family: Montserrat;
  font-size: 12px !important;
  font-style: normal;
  font-weight: normal !important;
  line-height: 22px;
}
.create_start_point{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: left !important;
  justify-content: flex-start;
  position: absolute;
  left: 0.5px;
  top: 72.5px;

}
.create_end_point{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: left;
  justify-content: flex-start;
  position: absolute;
  right: 1px;
  top: 72.5px;
  z-index: 0;
}
.vertical_line {
  width: 1px;
  height: 10px;
  background-color:#ffffff;
  /* z-index: -1; */
  /* margin-top: 3px; */
}
.vertical_line_end{
  width: 1px;
  height: 10.5px;
  background-color: #ffffff !important;
}

.starting_time{
  font-size: 12px;
  font-family: Muli,Helvetica,Arial,sans-serif;
  /* color: white; */
  margin-bottom: 3px;
}

.ending_time{
  font-size: 12px;
  font-family: Muli,Helvetica,Arial,sans-serif;
  color: white;
  margin-bottom: 3px;
}