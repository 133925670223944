.loader-con{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // border: 1px solid red;
}
.loader-text{
    color: white;
    font-family: Montserrat;
    font-size: 16px;
}

.loader {
    margin-left: 10px;
    border: 3px solid #04a51f;
    border-radius: 50%;
    border-top:3px solid transparent;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
   
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
   
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }