@import '../../../utilities/theme/_theme.scss';
@import '../../../assets/fonts/typography.scss';

.TotalCard {
    width: 100%;
    margin: auto;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    box-shadow: unset !important;
    margin-top: 10px !important;
    box-sizing: border-box;
    //   border: 1px solid $lightCyan !important;
    //   background: linear-gradient(90deg, $white 0%, #f8ffff 100%) !important ;
}

.property {
    opacity: 0.8 !important;
    color: #dbdfea !important;
    //   @extend .c-poppins-regular;
    //   font-weight: 500 !important ;
    font-family: Montserrat !important;
    letter-spacing: 0 !important;
    font-size: 12px !important;
    display: flex;
}

.value {
    //   height: 31px !important ;
    color: #dbdfea !important;
    font-family: Montserrat !important;
    @extend .c-poppins-regular;
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    //   line-height: 24px !important;
    text-align: center;
}

.statuscard_row {
    margin: 0px -20px !important;
    width: calc(100% + 40px) !important;
}

.statuscard {
    padding: 0px;
    background-color: #24263a !important;
    cursor: text;
}

.statuscard_img {
    padding: 15px;
    cursor: text;

    .img {
        width: 35px;
        height: 35px;
    }
}

.statuscard_txt {
    //   padding-left: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;

    .ColorDiv0 {
        background-color: rgb(255 170 134 / 8%);
    }

    .ColorDiv1 {
        background-color: rgb(203 184 134 / 10%);
    }

    .ColorDiv2 {
        background-color: rgb(173 134 255 / 10%);
    }

    .ColorDiv3 {
        background-color: rgb(173 134 255 / 10%);
    }
    .ColorDiv4 {
        background-color: rgba(87, 76, 110, 0.1);
    }
}

.statuscard_icon {
    margin-left: auto;
}

@media only screen and (max-width: 1399px) {
    .statuscard_row {
        margin: 0px -15px !important;
        width: calc(100% + 30px) !important;
    }

    .statuscard_clmn {
        padding: 0px 15px !important;
    }
}

// @media only screen and (max-width: 1399px) {
//   .statuscard_clmn {
//     -webkit-flex-basis: 50%;
//     -ms-flex-preferred-size: 50%;
//     flex-basis: 50%;
//     max-width: 50%;
//   }
//   .statuscard {
//     padding: 5px 20px 5px 10px;
//     margin: 0px 0px 15px !important;
//   }
// }
@media only screen and (max-width: 767px) {
    .statuscard_clmn {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .statuscard {
        margin-bottom: 10px;
    }
}
