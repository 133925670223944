.forgotPwdShortDevice {
    min-height: 115vh !important;
}
.acgLoginPage {
    & :-webkit-autofill {
        transition-delay: 9999s;
    }
    font-family: 'Montserrat';
    background-color: #1d1e2c;
    background-size: cover;
    min-height: 100vh;
    .loginForm {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -22%);
    }
    .loginFormShortDev {
        transform: translate(-50%, -17%) !important;
    }
    .loginLogo {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }
    .loginPaper {
        background-color: #2b2d42;
        border-radius: 0px;
        padding: 40px;
    }
    .simpleLogin {
        .MuiSnackbar-root {
            // margin-left: -33%;
            // margin-top: 25px;
            left: 0 !important;
            right: 0 !important;
            margin: 0 auto;
        }
    }
    .createPass {
        .MuiSnackbar-root {
            margin-left: -28%;
            margin-top: 35px;
        }
    }
    .forgotPass {
        .MuiSnackbar-root {
            margin-left: -15%;
            margin-top: 25px;
        }
    }
    .loginFormHeader {
        text-align: center;
        color: #b1c2df;
        max-width: 400px;
    }
    .welcomeText {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 12px;
    }
    .loginText {
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 50px;
    }
    .copyrightLabel {
        color: white;
        position: absolute;
        right: 2%;
        bottom: 2%;
        font-size: 14px;
    }
    .forgotPwd {
        margin-top: 5px;
        text-align: end;
        color: #5d97f6;
        font-size: 12px;
        font-weight: 600;
    }
    .forgotPwdText {
        cursor: pointer;
    }
    .textBoxDiv {
        margin-top: 9px;
        margin-bottom: 9px;
        width: 400px;
    }
    .password-class {
        margin-top: 15px;
    }
    .buttonDiv {
        margin-top: 30px;
    }
    .simpleLoginButton.Mui-disabled {
        background-color: #384e7d !important;
        color: black !important;
    }
    .lockTextDiv {
        width: 400px;
    }
}
@media (max-width: 950px) {
    .acgLoginPage {
        background-position: center;
    }
}

@media (min-width: 360px) and (max-width: 420px) {
    .acgLoginPage {
        .loginPaper {
            padding: 25px;
        }
        .textBoxDiv {
            width: 265px;
        }
        .lockTextDiv {
            width: 265px;
        }
    }
}
@media (max-width: 320px) {
    .acgLoginPage {
        .loginPaper {
            padding: 25px;
        }
        .textBoxDiv {
            width: 180px;
        }
        .lockTextDiv {
            width: 180px;
        }
    }
}

@media (min-width: 1024px) {
    .acgLoginPage {
        .loginText {
            margin-bottom: 10px;
        }
    }
}


.resendOtpContainer{
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 15px;
    // gap: 2px;
    button{
        
        display: flex;
        gap:4px;
        color: #5d97f6;
        font-size: 12px;
        font-weight: 600;
    }
    button:disabled{
        color:#5d97f6;
        
    }
    
}