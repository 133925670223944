.attachment_wrapper {
    display: block;
    img {
        width: 85px;
        height: 85px;
        border-radius: 7px;
        object-fit: cover;
    }
    .h4 {
        color: #0f2198;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        margin: 0;
        // width: 85px;
        width: 100%;
        text-decoration: underline;
    }
    .closeBtn {
        position: absolute;
        //right: 0;
        cursor: pointer;
        padding: 4px;
        width: 26px;
        height: 26px;
    }
}

.files-list-item{
    display:block;
    .files-dropzone-list{
        display:flex;
        justify-content:center;
        align-items: center;
        height:100%;
        width:85px;
        height:85px;
        border:2px dashed #d3d3d3;
        color:#d3d3d3;
        font-weight:200;
        font-size:25px;
        cursor:pointer;
    }
    .noBorders{
        border:1px solid transparent !important;
    }
}
