.acgStepper {
    margin: 20px auto;
    .MuiStepConnector-root {
        left: calc(-53% + 20px);
        right: calc(47% + 20px);
    }
    .MuiStepConnector-line {
        border-color: #96a1ba;
    }
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        color: #c0c2d8 !important;
        font-weight: 500;
        font-size: 12px;
        font-family: "Montserrat";
    }
    .iconClassActive {
        color: #5d97f6 !important;
        .MuiStepIcon-text {
            fill: #24263a;
        }
    }
    .iconClass {
        color: #24263a !important;
        border: 1.5px solid #96a1ba;
        border-radius: 12px;
        .MuiStepIcon-text {
            fill: #96a1ba;
        }
    }
}
