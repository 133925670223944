@import "../../../utilities/theme/_theme.scss";
@import "../../../assets/fonts/typography.scss";

.TableBase .simplebar-content .MuiTableContainer-root {
  // overflow-x: auto;
}

.TableBase .simplebar-content {
  overflow: initial;
}

.TableBase .emptyTable {
  height: 40px;
  text-align: center !important;
}

.nodataRow .img {
  display: flex;
  flex-direction: column;
  margin-left: 45%;
  margin-bottom: 3%;
}

.TableBase .emptyTable td {
  // @extend .c-poppins-medium;
  font-size: 12px !important;
  text-align: center !important;
  color: #c0c2d8;
}

@media (max-width: 480px) {
  .TableBase .simplebar-content .MuiTableContainer-root {
    overflow-x: clip;
    min-width: fit-content;
  }
}

.DrawerTableBase .simplebar-content .MuiTableContainer-root {
  overflow-x: auto;
}

.DrawerTableBase .emptyTable {
  height: 40px;
}

.DrawerTableBase .emptyTable td {
  // @extend .c-poppins-medium;
  font-size: 12px !important;
  text-align: center;
}

.table_pagination {
  background-color: #2b2d42;
}

@media (min-width: 1100px) and (max-width: 1299px) {
  .TableBase .simplebar-content .MuiTableContainer-root {
    min-width: 800px;
    // overflow-x: auto;
  }
}