.alarmDetailDrawerDiv {
    font-family: Montserrat;

    .MuiPaper-root {
        background-color: #24263a;
        // min-height: 100vh;
    }

    .standardBox {
        width: 40vw;
        // min-height: 100%;
        // height:max-content;
        // background-color: #24263a;

        .baseDiv {
            padding: 20px;
            padding-bottom: 0px;
            border-bottom: 1px solid grey;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        .userDetailDiv {
            background-color: #2d2f44;
            width: 90%;
            margin: 0 auto;
            height: auto;
            align-items: center;
            margin-top: 20px;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            padding: 10px;
            color: white;

            .detailDivChild {
                width: 49%;
                text-align: center;
                color: #98bcf8;
                padding: 8px;

                p {
                    font-size: 10px;
                    margin-bottom: 0rem;
                    line-height: 15px;
                }

                h6 {
                    font-size: 12px;
                    margin-top: 7px;
                    word-break: break-word;
                }
            }

            .firstChild {
                border-right: 1px solid #414d62;
            }
        }

        .alarmDivTop {
            border-radius: 16px 16px 0px 0px !important;
        }

        .alarmDivBottom {
            margin-top: 0px !important;
            border-radius: 0px 0px 16px 16px !important;

            .detailDivChild {
                text-align: left !important;
                width: 100% !important;
                margin-left: 7%;
            }
        }
    }
}

@media (max-width: 767px) {
    .alarmDetailDrawerDiv {
        .standardBox {
            width: 100vw;
        }
    }
}