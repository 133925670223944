@import "../../../assets/fonts/typography.scss";

.tabContainer {
  .MuiAppBar-root {
    z-index: 1250;
  }
  .MuiTabs-flexContainer {
    padding: 0 24px;
  }
}
.tabs {
  .MuiButtonBase-root {
    color: #161a93;
    font-family:Montserrat;
  }

  .Mui-selected {
    color: #5d97f6;
    font-weight: 600 !important;
    outline: none;
    text-align: center;
    // @extend .c-poppins-semibold;
  }
  .MuiTab-root {
    // color: #c0c6ce;
    color: #c0c2d8;
    margin-bottom: 3px;
    // @extend .c-poppins-medium;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  }
  .MuiTabs-indicator {
    // background-color: #161a93
    background-color: #5d97f6;
    height: 4px;
    border-radius: 1px;
  }
}
.tabAppBar {
  top: 67px !important;
  background-color: white !important;
  border-top: 1px solid lightgrey;
  z-index: 1009 !important;
}
.tabAppBar .MuiTab-root {
  margin-left: 30px;
  padding: 0px 30px 0px 30px;
  margin-top: 5px;
}
.unfixTabAppBar {
  top: 0px !important;
  background-color: #2b2d42 !important;
  width: 99.5% !important;
  left: 3px;
  border-bottom: 1px solid rgb(137 139 167 / 30%);
  border-radius: 10px 10px 0px 0px;
}
.noRadius{
  border-radius:0px !important;
}

// @media (max-width: 480px) {
//   .tabAppBar {
//     top: 55px !important;
//     border-top: 1px solid grey;
//   }
//   .unfixTabAppBar {
//     top: 0px !important;
//     background-color: transparent !important;
//     margin-bottom: 20px !important;
//     z-index: 10 !important;
//     border-bottom: 1px solid #ebf0f6;
//   }
// }

// @media (max-width: 361px) {
//   .tabContainer {
//     display: 360px;
//   }
//   .unfixTabAppBar {
//     top: 0px !important;
//     background-color: transparent !important;
//     margin-bottom: 20px !important;
//     z-index: 10 !important;
//     border-bottom: 1px solid #ebf0f6;
//   }
// }

// @media (max-width: 480px) and (min-width: 361px) {
//   .tabContainer {
//     max-width: 400px;
//   }
//   .unfixTabAppBar {
//     top: 0px !important;
//     background-color: transparent !important;
//     margin-bottom: 20px !important;
//     z-index: 10 !important;
//     border-bottom: 1px solid #ebf0f6;
//   }
// }
// @media (min-width: 768px) and (max-width: 1200px) {
//   .tabAppBar .MuiTab-root {
//     margin-left: 20px;
//     padding: 0px 20px 0px 20px;
//   }
//   .unfixTabAppBar {
//     top: 0px !important;
//     background-color: transparent !important;
//     margin-bottom: 20px !important;
//     z-index: 10 !important;
//     border-bottom: 1px solid #ebf0f6;
//   }
// }
// @media (max-width: 900px) {
//   .tabAppBar {
//     visibility: hidden;
//   }
//   .unfixTabAppBar {
//     top: 0px !important;
//     background-color: transparent !important;
//     margin-bottom: 20px !important;
//     z-index: 10 !important;
//     border-bottom: 1px solid #ebf0f6;
//   }
// }
