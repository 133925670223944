@media (min-width: 1920px){
.dashboard.PMEC-Dash .MuiContainer-root {
    max-width: 1920px;
}
}
.datetofrom{
    background-color: #121212;
    padding: 6px 5px 2px 5px;
    color: #e0e0e0;
}
.datetofrom input[type='date']{
    color: #e0e0e0;
}
.datetofrom input[type='date']:first-child{
    margin-right: 10px;
}
.datetofrom .MuiInput-underline:before,
.datetofrom .MuiInput-underline:after{
    border: 0px;
}
.datetofrom p{
    line-height: 1.7;
}
.popupuploaded .MuiDialog-paper{
background-color: #121212;
}
.popupuploaded .MuiDialogTitle-root{
color: #e0e0e0;
}
.datetofrom button{
    padding: 0px;
    position: relative;
    top: -2px;
    min-width: 24px;
}

.dateuichangetop .MuiInput-formControl {
    border: 1px solid #5d5e60;
    border-radius: 4px;
    padding: 0 5px;
}
.dateuichangetop .MuiInput-underline:before,
.dateuichangetop .MuiInput-underline:hover:not(.Mui-disabled):before,
.dateuichangetop .MuiInput-underline:after{
    border: 0px;
}
.addremovcls button.Mui-disabled{
    opacity: 0;
}
.no-spinners input[type="number"]::-webkit-inner-spin-button,
   .no-spinners input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
}
.plantNameTA .MuiInputBase-colorPrimary{
    border-radius: 10px;
}
.fontTxt svg text{
    font-size: 14px;
}
.radiolabls.labcolor .MuiTypography-root{
    color: #e0e0e0 !important;
}
.colorlab svg{ fill: #fff;}
/* For Graph UI CSS Start */
.dashboarGraph{
    background-color: #24263a;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
}
/* For Graph UI CSS End */
.colorlab svg, .arrowColors svg{ fill: #e0e0e0;}
.createReport.clc-cls{
padding-left: 20px;
padding-right: 20px;
}
.tableRow.clcTd .MuiTableCell-root:nth-child(7){
    width: 100px;
}
.tableRow.clcTd .MuiTableCell-root:nth-child(8){
    width: 200px;
}
.clclabel{
    min-height: 450px;
}
.goldenBatchCls .tableToolbar {
    /* position: absolute;
    width: 140px;
    right: 0;
    top: -7px;
    z-index: 9999; */
    padding-left: 14px;
    padding-right: 14px;
}

.gblegends .recharts-reference-area path{
    fill-opacity: unset;
  }
.yaxisbar {
    position: absolute;
    z-index: 9;
    top: 37%;
    transform: rotate(-90deg);
    left: -22px;
    color: #e0e0e0;
    font-family: Montserrat;
    font-size: 11px;
    text-align: center;
    min-width: 205px;
}
.xaxisbar {
    color: #e0e0e0;
    font-family: Montserrat;
    font-size: 12px;
    text-align: center;
    /* width: 50%;
    margin-left: 50%; */
}
.divwrap{
    width: 100%;
    float: right;
    display: grid;
    margin-top: -36px;
}
.right-content {
    /* background: #000; */
    padding: 10px 0px;
    float: right;
    width: 23%;
    margin-left: 1%;
    border-radius: 8px;
    margin-bottom: 10px;
}
.right-content .MuiGrid-item{
    /* background: #000; */
    height: 245px;
    overflow: auto;
}
.AlarmDot, .AlertsDot {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-right: 3px;
    display: inline-block;
}
.AlarmDot{
    background: #ffff00;
}
.AlertsDot{
    background: white;
}
.left-content{
    /* background: #000; */
    padding: 10px 0px;
    float: left;
    width: 75%;
    border-radius: 8px;
    margin-bottom: 10px;
}
.left-content .MuiGrid-item:first-child{
    float: left;
    position: relative;
    left: -60px;
    z-index: 9;
    height: 100%;
}
.left-content .MuiGrid-item:last-child{
    float: left;
    width: 100%;
}
.left-content .recharts-cartesian-axis-ticks text {
font-size: 12px;
}
.left-content .recharts-legend-item-text{
font-size: 12px;
text-transform: capitalize;
}
.goldenBatchCls .filter{
    position: absolute !important;
    top: -45px !important;
    z-index: 9999 !important;
    right: 13px !important;
}
.filterdata p.ptag{
background: #1d1e2c;
padding: 5px 8px;
margin-right: 5px;
color: #e0e0e0;
font-size: 11px;
border-radius: 0px;
border: 1px solid rgba(137, 139, 167, 0.3);
}
.filterdata p.itag{
    color: #e0e0e0;
    font-size: 12px;
}
.left-content .recharts-tooltip-wrapper .mtbP{
margin-bottom: 5px;
font-size: 12px;
}
.left-content .buttonwrap button{
    min-width: 72px;
    font-size: 12px;
}
.left-content .gbDivWrap .buttonwrap button {
    min-width: 72px;
    font-size: 11px;
    padding: 0px !important;
    height: 24px;
}
.left-content .MuiSlider-thumb {
    height: 14px;
    width: 14px;
}
.left-content .MuiSlider-valueLabel:before {
    width: 4px;
    height: 4px;
}
/* .left-content .MuiSlider-vertical.MuiSlider-colorPrimary{
width: 0;
} */
.left-content .recharts-curve.recharts-tooltip-cursor{
stroke: #2b2d42!important;
}
.arrowSwap svg{
    fill: #fff;
    background-color: #1d1e2c;
    padding: 0px;
    border-radius: 8px;
    cursor: pointer;
}
.arrowSwap {
    position: absolute;
    top: 0px;
    z-index: 99999;
    right: 16px;
}

.arrowSwapActive svg{
    fill: #fff;
    background-color: #1d1e2c;
    padding: 0px;
    border-radius: 8px;
    cursor: pointer;
}
.arrowSwapActive {
    position: absolute;
    z-index: 99999;
    right: 16px;
}
.abcd{
    /* width: 900px !important; */
}
.xyzz{
    /* width: 1200px !important; */
}
.left-content.notfilter{
    width: 76%;
    }
.left-content.filterapplied{
width: 100%;
}
.left-content .recharts-wrapper{
width: 100% !important;
}
.parameterLimitupdate .TableBase, .goldenBatchesList .TableBase{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.notificationlog table.actualTable,
.prevmenconfg table.actualTable{
    width: 2000px;
}
.machineAlarmtab table.actualTable,
.prevMWOrder table.actualTable{
    width: 1600px;
}
.tabmachine table.actualTable{
    width: 1500px;
}
.tabmachine .actualTable tbody td:nth-child(1){
    width: 26%;
}
.goldenBatchesList table.actualTable{
    width: 1400px;
}
.parameterLimitupdate table.actualTable{
    width: 1600px;
}
.parameterLimitupdate .actualTable thead th:nth-child(1){
    width: 14%;
}
.parameterLimitupdate .actualTable tbody td:nth-child(2){
    word-break: break-word;
}
.parameterLimitupdate .actualTable tbody td:nth-child(3){
    width: 30%;
}
.tablemappingLimit .actualTable tbody td:nth-child(3) .inputDiv input,
.tablemappingLimit .actualTable tbody td:nth-child(4) .inputDiv input{
    text-align: center;
}
.parameterLimitupdate .actualTable tbody td:nth-child(4){
    width: 10%;
    text-align: center;
}
.parameterLimitupdate .actualTable tbody td:nth-child(5){
    word-break: break-word;
    width: 10%;
    text-align: center;
}
.alertmsgdisplay{
    z-index: 99999;
}
.plantmodel .MuiSelect-select {
    color: #b1c2df !important;
}
.prevmenconfg .actualTable tbody td:nth-child(1){
    width: 12%;
}
.prevmenconfg .actualTable tbody td:nth-child(2){
    width: 12%;
}
.prevmenconfg .actualTable tbody td:nth-child(7){
    width: 12%;
}
.prevMWOrder .actualTable tbody td:nth-child(1){
    width: 12%;
}
.prevMWOrder .actualTable tbody td:nth-child(2){
    width: 12%;
}
.prevMWOrder .actualTable tbody td:nth-child(6){
    width: 12%;
}
.prevMWOrder .actualTable tbody td:nth-child(9){
    width: 8%;
}
.modelComponents .actualTable tbody td:nth-child(1){
width: 16%;
}
.plantmodel{
    display: flex;
}
.plantmodel .acgDropdown{
    margin-right: 20px;
}
.plantmodel .CMlabelDiv{
    width:120px;
    text-align: right;
    margin-right: 10px;
}
.plantmodel fieldset{
border: 1px solid #2b2d42 !important;
}
.plantmodel.withpanelbg{
        display: flex;
        background: #2b2d42;
        padding: 10px 10px;
        border-radius: 8px;
        margin-top: 10px;
}
.plantmodel.withpanelbg .CMlabelDiv{
    text-align: left;
    padding-left: 2px;
    margin-top: 0;
}
.innerparent {
    margin-right: 15px;
}
.notifyDetails,
.acgAlertbox .MuiModal-backdrop,
.alarmDetailDrawerDiv .MuiModal-backdrop,
.allreports .MuiModal-backdrop,
.powerBIDrawerDiv .MuiModal-backdrop,
.drawerDivAlert .MuiModal-backdrop, .clcDrawer .MuiModal-backdrop,
.machineMonitoringDrawerDiv .MuiModal-backdrop,
.filterDiv .MuiModal-backdrop, 
.drawerDiv .MuiModal-backdrop,
.detailDrawerDiv .MuiModal-backdrop{
    pointer-events: none;
}
.notifyDetails .MuiDialogContent-root.customtopcls {
    pointer-events: auto;
}
.goldenBatchCls .recharts-active-dot{
    display: none;
}
.gblegends .recharts-legend-wrapper>div{
    /* display: block !important; */
} 
.gblegends .recharts-legend-wrapper>div>div{
    width: auto;
    float: left;
}
.gblegends .recharts-legend-wrapper {
    width: 100% !important;
    bottom: -25px !important;
}
.filterdiv.production_dashboard .width_filter.clscolordot .MuiSelect-select {
color: #b1c2df;
font-weight: normal;
}
.goldenBatchesList .simplebar-content > .MuiTableContainer-root {
    max-height: calc(100vh - 326px);
}