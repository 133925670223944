.overviewContainer {
    padding-left: 10px;
    .overviewContainerFlexMain {
        display: flex;
        .FlexOne {
            flex: 65%;
            padding-right: 10px;
            .topRow {
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
            }
        }
        .FlexTwo {
            flex: 35%;
            border-left: 1px solid #383a50;
            .configureRowMainDiv {
                border-top: 1px solid #383a50;
                margin-top: 15px;
                padding-left: 10px;
                padding-right: 10px;
                .configureRow {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    color: white;
                    h4 {
                        font-size: 14px;
                    }
                }
                .configureRowChildMain {
                    //  display: flex;
                    // justify-content: space-between;
                    .configureRowChild {
                        width: 48%;
                        h5 {
                            color: #9cc2ff;
                            font-size: 10px;
                        }
                    }
                }
            }
            .bottomRow {
                border-top: 1px solid #383a50;
                border-bottom: 1px solid #383a50;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                padding: 10px;
                margin-bottom: 10px;
                h5 {
                    color: #dadcee;
                    font-size: 16px;
                    margin-bottom: 0px;
                }
                p {
                    color: #b1c2df;
                    font-size: 12px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .overviewContainerFlexMain {
        flex-wrap: wrap;
        .FlexOne {
            padding: 15px;
            .topRow {
                display: block !important;
                text-align: center;
                button {
                    margin-top: 10px;
                }
            }
        }
    }
}
