.powerBIDrawerDiv {
    .MuiPaper-root {
        background-color: #24263a;
        width: 97%;
        min-height: 100vh;
    }

    .BiReportsContainer {
        width: 100%;
        margin-left: 20px;
        height: 100%;
        background-color: #24263a;
    }

    .aggregate-report-style-class {
        width: 100%;
        height: 100%;
    }
}

.FeatureDrawerDiv {
    max-width: 94% !important;
    margin-left: 20px;
    height: 100%;
    background-color: #24263a;
}
.FeatureDrawerDiv.cppDiv{
    max-width: 100% !important;
}
.cpp-analysiscls .baseDiv {
        padding: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid grey;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
}
.mainarea{
    width: 100%;
    padding: 20px 0;
}
.leftcontent{
    float: left;
    width: 100%;
}
.cpp-filter-button {
    margin-top: 10;
}
.filtermenu {
    float: right;
    width: 24%;
    margin-left: 1%;
    padding: 15px;
    background-color: #2e304a;
}
.label_style {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    margin: 10px 0 5px 0;
    color: #c0c2d8;
}
.graph_div {
    float: left;
    width: 64%;
    background: #2e304a;
    height: 600px;
    padding: 10px;
}
.table_div{
    float: right;
    width: 35%;
    height: 600px
}
.powerBIDrawerDiv .table_div .MuiPaper-root {
    background-color: #2e304a; 
    width: 100%; 
    min-height: 100vh;
    border-radius: 0; 
    padding: 15px;
}
.table_div .MuiPaper-root th{
    font-size: 12px;
    padding: 8px 5px;
    font-family: 'Montserrat';
    border: 1px solid #444;
}
.table_div .MuiPaper-root tbody td{
    font-size: 12px;
    padding: 0px 5px;
    height: 40px;
    color: #e0e0e0;
    font-family: 'Montserrat';
    border: 1px solid #444;
}
.selectparm {
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    color: #e0e0e0;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat";
    height: 40px;
}
.dropparam {
    // justify-content: space-between;
    // display: flex;
}
.dropparam.dropp {
    float: right;
    width: 410px;
}
.dropparam .acgDropdown .dropdownbx {
    margin-left: 10px;
    height: 40px;
    border-radius: 4px;
}
.selectparm label{
    margin-bottom: 0;
}
.selectparm.selectt label{
// margin-top: 10px;
}
// .dropparam .acgDropdown{
//     margin-left: 10px;
//     margin-right: 10px;
// }
.dropparam .acgDropdown {
    margin-left: 10px;
    margin-right: 10px;
    float: left;
    width: 170px;
}
.buttonwrap{
    display: flex;
    justify-content: space-between;
}
.buttonwrap .MuiButtonBase-root.Mui-disabled {
        pointer-events: auto;
        cursor: no-drop;
        background: rgb(36, 38, 58); 
        color: rgb(224, 224, 224); 
        text-transform: capitalize; 
        line-height: 1; 
}
.buttonwrap .MuiButtonBase-root {
    background: #5d97f6; 
    color: rgb(224, 224, 224); 
    text-transform: capitalize; 
    line-height: 1; 
}
.buttonwrap .MuiButtonBase-root:hover{
    background-color: #1565c0;
}
.buttonwrap .MuiButtonBase-root.Mui-disabled:hover{
    background-color: rgb(36, 38, 58);
    opacity: 0.9;
}
.graphcontent{
    height: 300px;
    width: 100%;
    background-color: white;
    margin: 10px 0;
}