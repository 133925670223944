@media only screen and (min-width: 1600px) and (max-width: 1920px) {
    .filterMachineSr.oeeHome .barchartOP canvas {
        height: 380px !important;
    }  
    .filterMachineSr.oeeHome {
        height: 450px !important;
    } 
}
.CMlabelDiv {
    margin-top: 10px;
    margin-bottom: 5px;

    span {
        margin: 10px;
    }
}

.innerCardGroup {
    padding: 10px 0 0 0;
    height: calc(100vh - 9rem);
}

.scrollCont {
    height: calc(100% - 0rem);
    overflow-y: scroll;
}

.MuiCard-root.inCardGroup {
    background: #1f1f1f;
    display: flex;
    justify-content: space-between;
    height: 10rem;
}

.inCardGroup p {
    color: #e0e0e0;
}

.inCardGroup:hover {
    box-shadow: 6px 6px 20px rgba(0, 0, 0, .2), -12px 12px 40px rgba(0, 0, 0, .3);
}

.threeOption button {
    background-color: #000;
    border-radius: 0;
    font-family: "Montserrat-Medium";
    color: #e0e0e0;
}

.threeOption button:hover {
    background-color: #252525;
    opacity: 1;
}

.svgposition {
    position: relative;
    top: 0.83rem;
    right: 0.66rem;
}

.svgposition path {
    fill: #e0e0e0;
}

.wrapgroupcls {
    width: 100%;
    height: 100%;
}

.topGroupcls {
    max-width: 25%;
    margin-right: 1rem;
    float: left;
    background: #1f1f1f;
    box-shadow: -4px 4px 15px rgba(0, 0, 0, .1);
    border-radius: 4px;
    min-width: 24.25%;
    margin-bottom: 0.83rem;
    color: #e0e0e0;
}

.wrapgroupcls .topGroupcls:nth-child(4n) {
    margin-right: 0;
}

.all-labelcls h6 {
    color: #e0e0e0;
    font-family: "Montserrat-Medium";
}

.all-labelcls input {
    color: #e0e0e0 !important;
    -webkit-text-fill-color: #e0e0e0 !important;
}

.all-labelcls fieldset {
    border: 1px solid #5d5e60 !important;
}

.all-labelcls .MuiInputBase-root {
    height: 40px;
    margin-top: 0px;
    line-height: 1;
    font-size: 12px;
}

.butondrower .cancelBtn {
    font-size: 1.16rem;
    color: #e0e0e0;
    background: #2f2f2f;
    border-color: #2f2f2f;
    box-shadow: none;
    border-radius: 6px;
    height: 3.33rem;
    width: 8.33rem;
    float: left;
    margin-right: 1.66rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    font-family: "Montserrat-Medium";
    text-transform: capitalize;
}

.butondrower .cancelBtn:hover {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #3e3e3e !important;
    outline: 0;
    outline: 0 auto -webkit-focus-ring-color;
}

.butondrower .saveBtn {
    font-size: 1.16rem;
    font-family: "Montserrat-Medium";
    color: #000;
    background: #5d97f6;
    box-shadow: none;
    border-radius: 6px;
    height: 3.33rem;
    width: 8.33rem;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    text-transform: capitalize;
}

.butondrower .saveBtn.Mui-disabled {
    color: #e0e0e0;
    cursor: no-drop;
    pointer-events: bounding-box;
    font-size: 1.16rem;
    font-family: "Montserrat-Medium";
    color: #000;
    background: #5d97f6;
}

.butondrower .saveBtn:hover {
    color: #000;
    background-color: #6aa2ff;
    border-color: #6aa2ff;
}

.deletepopupbox.MuiBox-root {
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
    background: #1f1f1f;
    border-radius: 4px;
    box-shadow: none;
    border: 0;
    padding: 0;
}

.deletepopupbox .titleDiv {
    margin: 0;
    padding: 0;
    font-size: 1.33rem;
    color: #e0e0e0;
    font-family: "Montserrat-Medium";
    height: 5rem;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 2rem;
}

.bbt {
    border-bottom: 1px solid #313131;
}

.deletepopupbox p,
.deletepopupbox h2 {
    color: #e0e0e0;
    font-family: "Montserrat-Regular";
}

.deletepopupbox p {
    margin-bottom: 40px;
}

.padwraps {
    padding: 2rem;
}

.radiolabls {
    color: #e0e0e0;
}

.radiolabls path {
    fill: #e0e0e0;
}

.radiolabls .Mui-checked path {
    fill: #5d97f6;
}

// .MuiMenuItem-root:hover{
// color: #1f1f1f;
// }

[aria-labelledby="demo-simple-select-label-load"] li,
.MuiAutocomplete-popper .MuiAutocomplete-listbox {
    font-size: .83rem;
    color: #fff;
    font-family: "Montserrat";
}

[aria-labelledby="demo-simple-select-label-load"] li:hover,
.MuiAutocomplete-option[data-focus=true] {
    color: #5d97f6;
    font-size: .83rem;
    font-family: "Montserrat-Regular";
    text-decoration: none;
    background-color: #2a2c2d !important;
}

.textareaheight .MuiInputBase-root,
.multilinetxt .MuiInputBase-root {
    color: #ffff !important;
    height: 6rem !important;
    margin: 0px !important;
}

.user_demo {
    background: #1d1e2c !important;
    padding: 5px 0px 5px 5px;
    border-radius: 4px;
}

/* .MuiList-root.MuiList-padding li{
    background-color: #000 !important;
    color: #e0e0e0 !important;
    font-family: "Montserrat-Medium" !important;
} */
.user_demo li p {
    color: #e0e0e0;
}

.user_demo ul {
    overflow-y: scroll;
    max-height: 180px;
    height: 100%;
}

.user_demo li button path {
    fill: #e0e0e0;
}

.multilineheight .MuiInputBase-colorPrimary {
    max-height: 80px !important;
    height: auto !important;
    min-height: 40px;
}

#menu- .MuiSvgIcon-fontSizeMedium {
    fill: #b1c2df;
}

.MuiTooltip-popperArrow.MuiTooltip-popper {
    z-index: 15001;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    background-color: #1d1e2c;
}

.MuiTooltip-arrow::before {
    background-color: #1d1e2c !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    margin-top: 0px;
    height: 0.8em;
    width: 0.8em;
}

.MuiSelect-select span {
    padding: 3px 0px !important;
}

.MuiSelect-multiple {
    min-height: auto !important;
    font-size: 12px;
}

.chekboxmulti.MuiInputBase-root {
    font-size: 12px !important;
    min-width: 250px;
}
.minWidthCheckBox .chekboxmulti.MuiInputBase-root {
    min-width: 50px;
}
.mainradiocls .chekboxmulti.MuiInputBase-root {
    width: 250px;
}
.colorbgGraphHover .recharts-rectangle.recharts-tooltip-cursor{
    fill: none !important;
}
.mtforty .loader-con{
    margin-top: 40px;
}
.radiolabls .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    margin-top: 0px;
    height: 0.8em;
    width: 0.8em;
}

.mtop {
    margin-top: 8px !important;
}

.mabtop {
    margin-top: 14px !important;
}

.mbtop {
    margin-top: 10px;
}

.MuiTypography-root,
.MuiButtonBase-root,
.MuiInputBase-root {
    font-family: Montserrat !important;
}

.MuiInputBase-root input {
    padding-top: 0;
    padding-bottom: 0;
    height: 23px;
}

.rule-value input {
    padding: 2px 10px;
    border-radius: 10px;
    border: 1px solid #5d5e60 !important;
    margin: 0 5px;
}

.ruleGroup-header button {
    margin-bottom: 10px !important;
    padding: 0 10px !important;
}

.MuiButtonBase-root.MuiMenuItem-gutters {
    font-size: 14px;
}

.ruleGroup-body .MuiSelect-icon {
    fill: #e0e0e0;
}

.ruleGroup-body .MuiInput-underline:before {
    border-bottom: 1px solid #e0e0e0 !important;
}

.ruleGroup-body .MuiInput-underline,
.MuiAutocomplete-noOptions {
    color: #e0e0e0 !important;
    font-family: Montserrat !important;
}

#checkboxes-tags-demo-listbox li .MuiSvgIcon-root {
    fill: #e0e0e0;
}

.tooltiptxt {
    padding: 15px;
    line-height: 1.5;
}

.taskLabel {
    width: 80px;
    margin-top: 10px;
    margin-bottom: 5px;

    span {
        margin: 10px;
    }
}

.taskDesc {
    width: 200px;
    word-wrap: break-word;
    margin-top: 10px;
    margin-bottom: 5px;

    span {
        margin: 10px;
    }
}

.taskHeaders {
    color: #ffffff;
    margin-bottom: 20px;
}

.graphcontainer {
    margin: 10px 0px;
    // background-color: #2b2d42;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
}

.leftgraph,
.rightgraph {
    width: 49.5%;
    padding: 10px;
}

.heightgraphs .leftgraph canvas,
.heightgraphs .rightgraph canvas {
    height: 300px !important
}

// .leftgraph .donutcharts canvas{
//     height: auto !important;
//     width: 100% !important;
//   }
.OEEProdGraph {
    margin: 10px 0px;
    border-radius: 10px;
}

.leftgraphPD {
    background: #2b2d42;
    border-radius: 8px;
    padding: 10px;
}

.batchWiseGraph .leftgraphPD canvas {
    height: 250px;
}

.availabilityMatrix canvas {
    min-height: 200px !important;
    max-height: 300px !important;
    width: 100% !important;
}

.leftgraph {
    background: #2b2d42;
    border-radius: 8px;
    margin-right: 1%;
}

.comnclsgraph {
    background: #1d1e2c;
    padding: 10px;
    text-align: center;
    width: 48%;
    float: left;
    margin: 1% 1% 1% 1%;
    border-radius: 10px;
    height: 200px;
    color: #e0e0e0;
}

.leftgraph-four.comnclsgraph {
    margin-bottom: 0;
}

.mainradiocls label {
    color: #e0e0e0;
    margin: 8px 0 10px 0;
    font-size: 14px;
}

.mainradiocls .dropmulti label {
    margin: 0;
    font-size: 12px;
}

.filterMachineSr {
    background: #1d1e2c;
    padding: 10px;
    text-align: center;
    float: left;
    border-radius: 10px;
    height: 374px;
    color: #e0e0e0;
    width: 100%;
}
.filterMachineSr.oeeHome{
    height: 260px;
}
.filterMachineSr.oeeHome .barchartOP canvas{
height: 200px;
}
.mainradiocls {
    display: flex;
    margin-bottom: 6px;
    position: relative;
    align-items: center;
}

.mainradiocls>div {
    min-width: 160px;
}

.mainradiocls>div:nth-child(3) {
    position: absolute;
    right: 0px;
    top: -5px;
}

.mainradiocls input[type=radio] {
    margin-right: 5px;
    position: relative;
    top: 2px;
    cursor: pointer;
}

.daterangeletr .dateRangePickerInput input {
    letter-spacing: -0.5px;
}

.rightgraph {
    background: #2b2d42;
    border-radius: 8px;
}

.titleGraph {
    text-align: left;
    font-size: 16px;
    color: #e0e0e0;
}

.width_filterrs{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.wrapspace {
    max-width: 250px;
    width: 100%;
}
.machine-srfiler {
    margin: 0 5px;
    width: 48%;
}

.barchartPMEC canvas {
    height: 350px !important;
}
.plantOEEChart canvas{
    width: 100% !important;
}

.barchartPMEC.noheight canvas,
.performanceChart canvas {
    height: 280px !important;
}
.performanceChart.oEEParam canvas,
.performanceChart.machineDownTime canvas {
    margin-bottom: 20px !important;
}
.performanceChart.machineDownTime .legendcls{
    min-height: 60px;
    display: block;
    justify-content: center;
    height: 100%;
    max-height: 75px;
    overflow: auto;

}
.performanceChart.oEEParam{
    margin-top: 47px;
}
.performanceChart.oEEParam .legendcls{
    // height: 100%;
    // max-height: 100px;
    // overflow: auto;
    // display: block;
    // min-height: 60px;
}
.filterdiv {
    display: flex;
    background: #2b2d42;
    padding: 10px 5px;
    border-radius: 8px;
}

.pmec-tableInner .css-jjtu05 {
    height: 200px;
}

.pmec-tableInner .makeStyles-root-1 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.PMEC-Dash .unfixTabAppBar {
    width: 100% !important;
    left: 0px;
}

.chekboxmulti,
.muti_boxcheck {
    height: 40px;
    margin: 0 !important;
    max-width: 277px;
    width: 100%;
    font-size: 12px !important;
}

.chekboxmulti:hover,
.chekboxmulti .MuiOutlinedInput-notchedOutline,
.muti_boxcheck .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
}

.lableTitle {
    font-size: 13px;
    color: #e0e0e0;
    margin-bottom: 5px;
    width: 100%;
}
.width_filter.widthIncrease.widthDropbox .MuiInputBase-root.chekboxmulti{
    min-width: 200px!important;
    max-width: 250px !important;
}
.width_filter {
    width: 16%;
    margin: 0 0.66%;
}

.width_filter .MuiSelect-select {
    min-width: 145px;
}

.plantsearch {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 9991;
    width: 200px;
}

.donutcharts {
    position: relative;
    display: flex;
    align-items: center;
}

.percentvalue {
    position: absolute;
    z-index: 1;
    width: 100px;
    left: 0;
    right: 0;
    color: #e0e0e0;
    text-align: center;
    margin: 0 auto;
}

.percentvalue span.percentVal {
    font-size: 18px;
    color: #e0e0e0;
    width: 100%;
    display: block;
}

.percentvalue span.labelCharts {
    font-size: 11px;
    color: #e0e0e0;
    width: 100%;
    display: block;
}

.pmec-table .tableToolbar .searchBox {
    width: 250px;
}

.pmec-table .TableBase {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pmec-table .tableRow .MuiTableCell-root,
.pmec-table .tableColumn .MuiTableCell-root {
    width: 14%;
    text-align: center;
}

.pmec-table .tableRow .MuiTableCell-root:nth-child(1),
.pmec-table .tableColumn .MuiTableCell-root:nth-child(1) {
    text-align: left;
}

.pmec-table .tableColumn .MuiTableCell-root span {
    line-height: 18px;
}

.filterdiv.mt10 {
    margin-top: 10px;
}

.titleGraph.font14 {
    font-size: 14px;
}

.mainradiocls .dropmulti label.MuiInputLabel-animated {
    font-size: 12px !important;
    padding-left: 0 !important;
}

.mainradiocls .dropmulti label.Mui-focused.MuiInputLabel-animated {
    transform: translate(14px, 16px) scale(1) !important;
}

.oeedashboard .actualTable tbody td:nth-child(1) {
    width: 22%;
}

.inputDivselectparamGrafana {
    margin: 0px !important;
}

.nodatagraphana {
    margin-top: 10px;
    z-index: 0;
    position: relative;
    background-color: #2b2d43;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 360px;
}

.grafanavalue {
    border: 0;
}

.width_filter.colorfixes .MuiSelect-select span {
    color: #b1c2df;
}

.filterdiv.production_dashboard .width_filter.clscolordot .MuiSelect-select {
    color: #b1c2df;
    font-weight: normal;
}
.filterdiv.production_dashboard .width_filter .MuiSelect-select,
.filterdiv.production_dashboard .acgDropdown{
    min-width: 110px !important;
}
.filterdiv.production_dashboard .width_filter{
    width: 13% !important;
}
.filterdiv.production_dashboard .width_filter.dateWidth{
    min-width: 118px;
}
.filterdiv.production_dashboardBottom{
width: 1300px;
overflow-x: auto;
}
.filterdiv.production_dashboardBottom .width_filter.dateWidth {
    min-width: 135px;
}
.filterdiv.production_dashboardBottom .width_filter{
    width: 35% !important;
}
.widthmindiv{
    min-width: 160px;
}
.width_filter.dateWidth .custom-date-picker .MuiInputBase-input, 
.width_filter.dateWidth .custom-date-picker .MuiButtonBase-root {
    padding: 9px 0px 9px 14px;
}
ul:has(> li.ulSelectProduct) {
    overflow-x: auto !important;
    background-color: rgba(25, 118, 210, 0.08) !important;
}

ul:has(> li.ulSelectProduct) li:first-child {
    padding: 0 !important;
}

.ulSelectProduct {
    padding: 0px !important;
}

ul:has(> li.ulSelectBatchRecipe) li:first-child span.MuiListItemText-primary,
.ulSelectBatchRecipe span.MuiListItemText-primary,
ul:has(> li.ulSelectProduct) li:first-child span.MuiListItemText-primary,
.ulSelectProduct span.MuiListItemText-primary {
    font-size: 12px !important;
}

.blockcls {
    display: flex;
    width: 100%;
    margin: 10px 0 20px 0;
}

.innerKPIs {
    float: left;
    width: 24%;
    background: #1D1E2C;
    padding: 10px;
    color: #e0e0e0;
    border: 1px solid #4d4d51;
    border-radius: 8px;
    margin-right: 1.33%;
    text-align: center;
    font-family: Montserrat;
    height: 65px;
}

.innerKPIs:last-child {
    margin-right: 0%;
}

.valuesKPIs {
    font-size: 18px;
    font-weight: bold;
    min-height: 27px;
}

.textKPIs {
    font-size: 12px;
}