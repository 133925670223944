.container{
    // border: 1px solid red;
}
.header{
display: flex;
justify-content: flex-end;
align-items: center;
height: 60px;
}
.search {
    border: 1px solid #2b2d42;
    background-color: #2e3047 !important;
    height: 40px;
    width: 430px;
    color: white !important;
    border-radius: 10px;
    position: relative;
    font-family: Montserrat;
    margin-right: 10px;
}
.paginationBox  {
   position:  relative;
   margin-left: 15px;
}.searchBoxIconCancel {
    position: absolute;
    right: 10px;
    cursor: pointer;
}