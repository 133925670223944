@import "../../../utilities/theme/_theme.scss";

.customTableMenuAction {
  color: #ffffff;
}
.userMenu{
  z-index:99999;
  .MuiPaper-root {
    background-color:#1D1E2C;
    border: 1px solid rgba(58,68,86,0.6);
    border-radius:6px;
    box-shadow:0px 3px 10px(0,0,0,0.1);
    .MuiList-root {
      padding-top:0px;
      padding-bottom:0px;
    }
    .MuiButtonBase-root{
      padding:10px;
    }
    .MuiButtonBase-root:not(:last-child) {
      border-bottom:1px solid #3A4456;
    }
    .MuiButtonBase-root {
      color:#B1C2DF;
    }
  }
}
