.acgAutoComplete {
    font-family: 'Montserrat';
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .MuiOutlinedInput-root {
        background-color: #1d1e2c;
        border-radius: 10px;
        border-width: 1px;
        border-color: #2b2d42;
        align-content: center;
        justify-content: center;
        width: 100%;
        color: #b1c2df;
        .Mui-focused fieldset {
            border: none;
        }
    }
    .MuiAutocomplete-input {
        color: #b1c2df;
        font-size: 12px;
        font-weight: 400;
    }
    svg {
        color: #dbdfea !important;
    }
}
