.acgAlertbox {
    border: 1px solid #2b2d42;
    font-family: 'Montserrat';
    z-index: 99999 !important;
    .MuiModal-backdrop {
        opacity: 0;
        background-color: rgba(4, 4, 4, 1);
    }
    .cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }
    .buttons {
        justify-content: center !important;
        display: flex !important;
        margin-top: 5px;
        margin-bottom: 30px !important;
        .leftButton {
            height: 44px;
            height: 46px;
            width: 178px;
            margin-right: 20px;
        }
        .rightButton {
            height: 44px;
            height: 46px;
            width: 178px;
        }
    }
    .textValue {
        color: #b1c2df;
        font-size: 12px;
        font-weight: 500;
    }
    .MuiDialogContent-root {
        border-top: none !important;
        border-bottom: none !important;
        display: flex;
        justify-content: center;
    }
    .changedText {
        text-align: center;
    }
    .textHeader {
        color: #b1c2df;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
    }

    .MuiDialogActions-root {
        box-sizing: unset !important;
    }
}

@media (max-width: 420px) {
    .acgAlertbox {
        .textHolder {
            margin-left: 50px !important;
            display: table-row !important;
        }
        .buttons {
            .leftButton {
                width: 112px !important;
            }
            .rightButton {
                width: 112px !important;
            }
        }
    }
}

@media (min-width: 421px) and (max-width: 540px) {
    .acgAlertbox {
        .textHolder {
            margin-left: 45px !important;
        }
        .changedText {
            margin-left: 45px !important;
            display: contents !important;
        }
    }
}
@media (max-width: 300px) {
    .acgAlertbox {
        .buttons {
            .leftButton {
                width: 80px !important;
                margin-right: 10px;
            }
            .rightButton {
                width: 80px !important;
            }
        }
    }
}
